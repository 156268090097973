<template>
  <div class="apply">
    <div
      v-for="(nav, index) in tabsNav"
      :key="index"
      class="nf-tabs-nav-label"
      :class="{ 'is-active': currentNav === index }"
      @click="switchNav(index, nav)"
    >
      <i class="icon icon-await"></i>
      <span>{{ nav }}</span>
    </div>
    <div v-if="currentNav == 0">
      <div class="nf-tabs-filter-form">
        <div class="nanfanregister-content-btn" style="padding:20px 10px;">
          <el-button type="primary" size="medium" @click="addDialog" icon="el-icon-plus">新增申报账号</el-button>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%;" empty-text="暂无申请记录">
        <el-table-column label="单位名称" prop="organizationName" align="center"></el-table-column>
        <el-table-column label="上报人" prop="nikeName" align="center"></el-table-column>
        <el-table-column label="账号" prop="userName" align="center"></el-table-column>
        <el-table-column label="管理" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-tickets"
              @click="handleEdit(scope.$index, scope.row,1)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleEdit(scope.$index, scope.row,2)"
              icon="el-icon-delete"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <page-cut :total="total" @pageChange="pageChange" :currentPage="pageNum" :pageSize="pageSize"></page-cut>
    </div>
    <div v-if="currentNav == 1">
      <div class="nf-tabs-filter-form">
        <div class="nanfanregister-content-btn" style="padding:20px 10px;">
          <el-button type="primary" size="medium" @click="addSecondDialog" icon="el-icon-plus">新增项目</el-button>
        </div>
      </div>
      <el-table :data="tableSecondData" style="width: 100%;" empty-text="暂无申请记录">
        <el-table-column label="项目名称" prop="itemName" align="center">
        </el-table-column>
        <el-table-column label="创建人" prop="userName" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="createdDate" align="center"></el-table-column>
        <el-table-column label="管理" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-tickets"
              @click="handleSecondEdit(scope.$index, scope.row,1)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleSecondEdit(scope.$index, scope.row,2)"
              icon="el-icon-delete"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <manage-form v-model="dialogFormVisible" :id="id" @refresh="getSearch" v-if="dialogFormVisible"></manage-form>
    <manage-second-form v-model="dialogSecondFormVisible" :id="id" @refresh="getSecondSearch" v-if="dialogSecondFormVisible"></manage-second-form>
  </div>
</template>
<script>
import manageForm from './manage-form.vue';
import manageSecondForm from './manage-second-form.vue';
import pageCut from "@/component/pagination/index.vue";
export default {
  name:'apply',
  data(){
    return {
      tabsNav: ['申报账号管理', '项目管理'],
      currentNav: 0,
      id:'',
      tableData:[],
      dialogFormVisible: false, 
      dialogSecondFormVisible: false,
      dialogDetailVisible: false,
      pageSize: 10,
      pageNum: 1,
      total: 1,
      tableSecondData: [],
    }
  },
  components:{
    'manage-form': manageForm,
    'manage-second-form': manageSecondForm,
    pageCut
  },
  created(){
    this.getData();
  },
  methods:{
    // 申报账号管理列表
    async getData() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      const api = this.$fetchApi.declareList.api;
      const data = await this.$fetchData.fetchPost(params, api, "json");
      if (data.code === "200") {
        this.tableData = data.result.list || [];
        this.total = data.result.total;
      }
    },
    // 项目管理列表
    async getSecondData() {
      const api = this.$fetchApi.projectList.api;
      const data = await this.$fetchData.fetchPost({}, api, "");
      if (data.code === "200") {
        this.tableSecondData = data.result || [];
      }
    },
    switchNav(index) {
      this.currentNav = index;
    },
    pageChange(num){
      this.pageNum =Number(num.page_index);
      this.getData();
    },
    getSearch:function(type) {
      if(type === '1'){
        this.filterForm.forEach(item => {
          item.value = "";
        })
      }
      this.pageNum = 1;
      this.getData()
    },
    getSecondSearch:function(type) {
      if(type === '1'){
        this.filterForm.forEach(item => {
          item.value = "";
        })
      }
      this.pageNum = 1;
      this.getSecondData()
    },
    // 新增1
    addDialog() {
      this.id = '';
      this.dialogFormVisible = true;
    },
    // 新增2
    addSecondDialog() {
      this.id = '';
      this.dialogSecondFormVisible = true;
    },
    handleEdit(index,el,i){
      // 查看详情
      if( i === 1 ) {
        this.id = el.id;
        this.dialogFormVisible = true;
      }else if( i === 2 ){
        // 删除申报账号
         this.$confirm('您确定要删除？', '删除申报账号', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.deleteApply(el.id);
        })
      }
    },
    handleSecondEdit(index,el,i){
      // 编辑
      if( i === 1 ) {
        this.id = el.id;
        this.dialogSecondFormVisible = true;
      }else if( i === 2 ){
        // 删除申报账号
         this.$confirm('您确定要删除？', '删除项目', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.deleteSecondApply(el.id);
        })
      }
    },
    // 删除
    async deleteApply(id) {
      const api = this.$fetchApi.declareDelete.api;
      const data = await this.$fetchData.fetchPost({}, api + id);
      if(data.code !== '200'){
        this.$message.error(data.message);
        return false;
      }
      this.$message.success({message:'删除成功'});
      this.pageNum = 1;
      this.getData()
    },
    // 删除2
    async deleteSecondApply(id) {
      const api = this.$fetchApi.projectDelete.api;
      const data = await this.$fetchData.fetchPost({}, api + id);
      if(data.code !== '200'){
        this.$message.error(data.message);
        return false;
      }
      this.$message.success({message:'删除成功'});
      this.getSecondData()
    }
  },
  watch: {
    currentNav(val) {
      if (val == 0) {
        this.getData();
      } else {
        this.getSecondData();
      }
    }
  }
}
</script>
<style lang="less" scoped>
.overflow-text{
  overflow: hidden;/*超出部分隐藏*/
  white-space: nowrap;/*不换行*/
  text-overflow:ellipsis;/*超出部分文字以...显示*/
}

.apply{
  background: rgba(249, 251, 255, 0.99);
  min-height: 400px;
  .filter-form{
    padding: 10px 0px 0px 10px;
  }
  &-content {
    box-sizing: border-box;
    padding: 0 20px;
    &-title {
      position: relative;
      height: 60px;
      line-height: 60px;
      text-indent: 20px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -4px;
        display: block;
        width: 8px;
        height: 8px;
        background: rgba(51, 128, 254, 1);
        border-radius: 50%;
      }
    }
    /deep/ .el-table td {
      border: none;
      padding: 2px 0;
      // margin: 5px 0;
      // background-color:#fff;
      // box-sizing: border-box;
    }
    &-btn {
      text-align: center;
      height: 150px;
      line-height: 50px;
    }
    &-item {
      width: 80%;
      margin: 10px auto;
      text-align: center;
      span {
        margin-right: 35px;
        font-size: 18px;
      }
      /deep/ .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      /deep/ .el-icon-arrow-down {
        font-size: 16px;
      }
    }
  }
}

/deep/ .nf-tabs-nav {
  display: inline-block;
  background: #ffffff;
  width: 100% !important;
  box-shadow: 0 2px 3px 0px rgba(38, 90, 225, 0.15);

  &-label {
    display: inline-block;
    padding: 16px 26px;
    font-size: 16px;
    color: #333333;
    position: relative;
    cursor: pointer;

    &.is-active {
      color: #3380fe;
      border-bottom: 3px solid #3380fe;

      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #3380fe;
        box-shadow: 0 2px 3px 0px rgba(51, 128, 254, 0.7);
      }
    }
  }
}


</style>