<template>
  <div class="nanfanregister">
    <div class="breadWrap">
      <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>南繁管理</el-breadcrumb-item>
          <el-breadcrumb-item>重点项目</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="nanfanregister-content auto-center">
      <!-- <div class="nanfanregister-content-title">南繁备案情况</div> -->
       <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <p v-for="(nav, index) in tabsNav"
          :key="index">
           <a class="nf-tabs-nav-label"
            :class="{'is-active': activeNav === index}"
            @click="activeNavBtn(index)"> 
            <i :class="nav.iconClass" class="icon"></i>
            <span>{{nav.name}}</span>
          </a>
        </p>
       
      </div>
      <div class="nf-tabs-content">
        <!--  第一个tab--start -->
        <apply v-if="activeNav === 0" />
        <fillIn v-if="activeNav === 1 && isShow" />
        <manage v-if="activeNav === 1 && !isShow" />
        <statistics v-if="activeNav === 2" />
        <div>
        </div>
        <!--  第一个tab--end -->
        <!--  第二个tab--end -->
        <!-- <div v-show="activeNav === 1">
            曾经的你
        </div> -->
        <!--  第二个tab--end -->
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// import breadCrumb from "@/component/breadcrumb/breadcrumb.vue";
import apply from './component/apply.vue';
import manage from './component/manage.vue';
import statistics from './component/statistics.vue';
import fillIn from './component/fillIn.vue';
// import pageCut from "@/component/pagination/index.vue";
export default {
  data() {
    return {
      activeNav: 0,
      tabsNav: [
      ]
    };
  },
  components: {
    // breadCrumb,
    // pageCut,
    apply,
    fillIn,
    manage,
    statistics
  },
  created() {
    this.isNanfanFun();
  },
  methods: {
    // 查看是否为南繁人员
    async isNanfanFun() {
      const data =  await this.$fetchData.fetchGet({}, this.$fetchApi.isNanfanPerson.api, 'json');
      if (data.code == '200' && data.result) {
        this.tabsNav = [
          {name: "项目填报", iconClass: 'icon-processing', status: true, number: 0},
          {name: "项目管理", iconClass: 'icon-await', status: true, number: 1},
          {name: "统计分析", iconClass: 'icon-draft', status: true,  number: 2}
        ];
        this.isShow = false;
      } else {
        this.tabsNav = [
          {name: "项目填报", iconClass: 'icon-processing', status: true, number: 0},
          {name: "填报统计", iconClass: 'icon-processing', status: true, number: 1}
        ];
        this.isShow = true;
      }
    },
    activeNavBtn(index) {
      this.activeNav = index;
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__item {
    font-size: 18px;
    height: 60px;
    line-height: 60px;
}
/deep/ .el-tabs__item.is-active {
  color: #265ae1;
}
/deep/ .el-tabs__active-bar {
  height: 3px;
  background: #265ae1;
}
//  左侧导航
.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/officialDocument/icon_nw.png");
    }

    &-processing {
      background-image: url("../../assets/images/officialDocument/icon_bw.png");
    }

    &-draft {
      background-image: url("../../assets/images/dutyManagement/icon_excel.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
.breadWrap {
  border-bottom: 1px solid #ccc;
  .el-breadcrumb {
    width: 1200px;
    margin: 20px auto;
    font-size: 16px;
  }
}
</style>