<template>
  <div>
    <div
      v-for="(nav, index) in tabsNav"
      :key="index"
      class="nf-tabs-nav-label"
      :class="{ 'is-active': currentNav === index }"
      @click="switchNav(index)"
    >
      <i class="icon icon-await"></i>
      <span>{{ nav }}</span>
    </div>
    <div class="nf-tabs-content">
      <div class="nf-tabs-cont" v-show="currentNav === 0">
        <div class="nf-tabs-filter-form">
          <el-form :inline="true" class="filter-form">
            <el-form-item label="年份筛选">
              <el-select
                class="form-row-item"
                v-model="firstDate"
                placeholder="请选择年份"
                @change="firstDateBtn"
              >
                <el-option label="所有年份" value=""></el-option>
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, i) in dateList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申报项目">
              <el-select
                class="form-row-item"
                v-model="firstType"
                placeholder="请选择项目"
                @change="projectBtn"
              >
                <el-option label="所有项目" value=""></el-option>
                <el-option
                  :label="item.itemName"
                  :value="item.itemType"
                  v-for="(item, i) in projectList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <p class="second_title">{{firstDate ? firstDate : '2015-2021'}}年{{firstTypeText ? firstTypeText : '所有项目'}}资金下达和支出情况</p>
            <div class="number_content">
              <div class="all_number">
                <p class="margin_left">
                  <img class="margin-rights" src="../../../assets/images/officialDocument/expenditure.png" alt=""/>
                  总下达资金：{{firstInfo.releasedTotal ? firstInfo.releasedTotal : 0}}万元
                </p>
                <p>中央：{{firstInfo.releasedFundsCentral ? firstInfo.releasedFundsCentral : 0 }}万元</p>
                <p>省级：{{firstInfo.releasedFundsProvince ? firstInfo.releasedFundsProvince : 0}}万元</p>
                <p>地方：{{firstInfo.releasedFundsLocal ? firstInfo.releasedFundsLocal : 0}}万元</p>
              </div>  
              <div class="all_number">
                <p class="margin_left">
                  <img class="margin-rights"
                    src="../../../assets/images/officialDocument/income.png"
                    alt=""
                  />总支出资金：{{firstInfo.expenditureTotal ? firstInfo.expenditureTotal : 0}}万元
                </p>
                <p>中央：{{firstInfo.expenditureFundsCentral ? firstInfo.expenditureFundsCentral : 0}}万元</p>
                <p>省级：{{firstInfo.expenditureFundsProvince ? firstInfo.expenditureFundsProvince : 0}}万元</p>
                <p>地方：{{firstInfo.expenditureFundsLocal ? firstInfo.expenditureFundsLocal : 0}}万元</p>
              </div>
              <div class="all_number">
                <p class="margin_lefts">
                  总支出占比：{{firstInfo.expenditureTotalPercentage ? firstInfo.expenditureTotalPercentage : 0}}%
                </p>
                <p>中央：{{firstInfo.expenditureFundsCentralPercentage ? firstInfo.expenditureFundsCentralPercentage : 0}}%</p>
                <p>省级：{{firstInfo.expenditureFundsProvincePercentage ? firstInfo.expenditureFundsProvincePercentage : 0}}%</p>
                <p>地方：{{firstInfo.expenditureFundsLocalPercentage ? firstInfo.expenditureFundsLocalPercentage : 0}}%</p>
              </div>
              <div><el-button class="pull-right export-btn" type="primary" @click="exportFirstTable" >导出</el-button></div>
            </div>
            <el-form-item label="年份筛选">
              <el-select
                class="form-row-item"
                v-model="secondDate"
                placeholder="请选择年份"
                @change="secondDateBtn"
              >
                <el-option label="所有年份" value=""></el-option>
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, i) in dateList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目地点">
              <el-select
                class="form-row-item"
                v-model="secondAddressId"
                placeholder="请选择项目地点"
                @change="addressBtn"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, i) in addressList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申报项目">
              <el-select
                class="form-row-item"
                v-model="secondType"
                placeholder="请选择项目"
                @change="projectSecondBtn"
              >
                <el-option label="所有项目" value=""></el-option>
                <el-option
                  :label="item.itemName"
                  :value="item.itemType"
                  v-for="(item, i) in projectList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <p class="second_title">{{secondDate ? secondDate : '2015-2021'}}年{{secondAddress}}{{secondTypeText ? secondTypeText : '所有项目'}}资金下达和支出情况</p>
            <div class="number_content" v-if="!secondTypeText">
              <div class="all_number">
                <p class="margin_left">
                  <img class="margin-rights" src="../../../assets/images/officialDocument/expenditure.png" alt=""/>
                  总下达资金：{{secondInfo.releasedTotal ? secondInfo.releasedTotal : 0}}万元
                </p>
                <p>中央：{{secondInfo.releasedFundsCentral ? secondInfo.releasedFundsCentral : 0}}万元</p>
                <p>省级：{{secondInfo.releasedFundsProvince ? secondInfo.releasedFundsProvince : 0}}万元</p>
                <p>地方：{{secondInfo.releasedFundsLocal ? secondInfo.releasedFundsLocal : 0}}万元</p>
              </div>  
              <div class="all_number">
                <p class="margin_left">
                  <img class="margin-rights"
                    src="../../../assets/images/officialDocument/income.png"
                    alt=""
                  />总支出资金：{{secondInfo.expenditureTotal ? secondInfo.expenditureTotal : 0}}万元
                </p>
                <p>中央：{{secondInfo.expenditureFundsCentral ? secondInfo.expenditureFundsCentral : 0}}万元</p>
                <p>省级：{{secondInfo.expenditureFundsProvince ? secondInfo.expenditureFundsProvince : 0}}万元</p>
                <p>地方：{{secondInfo.expenditureFundsLocal ? secondInfo.expenditureFundsLocal : 0}}万元</p>
              </div>
              <div class="all_number">
                <p class="margin_lefts">
                  总支出占比：{{secondInfo.expenditureTotalPercentage ? secondInfo.expenditureTotalPercentage : 0}}%
                </p>
                <p>中央：{{secondInfo.expenditureFundsCentralPercentage ? secondInfo.expenditureFundsCentralPercentage : 0}}%</p>
                <p>省级：{{secondInfo.expenditureFundsProvincePercentage ? secondInfo.expenditureFundsProvincePercentage : 0}}%</p>
                <p>地方：{{secondInfo.expenditureFundsLocalPercentage ? secondInfo.expenditureFundsLocalPercentage : 0}}%</p>
              </div>
              <div><el-button class="pull-right export-btn" type="primary" @click="exportSecondTable">导出</el-button></div>
              <!-- echarts图 -->
              <div class="echarts_class" v-for="(item, i) in echartsList" :key="i">
                <p>项目{{i + 1}}：{{item.itemName}}</p>
                <div class="firstEchartsClass">
                  <div class="firstLeftEchartsClass">
                    <p class="title">资金下达和支出：</p>
                    <p class="center">下达：{{item.releasedTotal}}万元  支出：{{item.expenditureTotal}}万元</p>
                    <div class="firstLeftEcharts" ref="" :id="'firstLeftEcharts' + '' + i"></div>
                  </div>
                  <div class="firstRightEchartsClass">
                    <p class="titles">支出占比：</p>
                    <p class="centers">总体支出占比：{{item.expenditureTotalPercentage ? item.expenditureTotalPercentage : 0}}%</p>
                    <div class="firstRightEcharts" :id="'firstRightEcharts' + '' + i"></div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="number_content">
                <div class="all_number">
                  <p class="margin_left">
                    <img class="margin-rights" src="../../../assets/images/officialDocument/expenditure.png" alt=""/>
                    总下达资金：{{secondInfo.releasedTotal ? secondInfo.releasedTotal : 0}}万元
                  </p>
                  <p>中央：{{secondInfo.releasedFundsCentral ? secondInfo.releasedFundsCentral : 0}}万元</p>
                  <p>省级：{{secondInfo.releasedFundsProvince ? secondInfo.releasedFundsProvince : 0}}万元</p>
                  <p>地方：{{secondInfo.releasedFundsLocal ? secondInfo.releasedFundsLocal : 0}}万元</p>
                </div>  
                <div class="all_number">
                  <p class="margin_left">
                    <img class="margin-rights"
                      src="../../../assets/images/officialDocument/income.png"
                      alt=""
                    />总支出资金：{{secondInfo.expenditureTotal ? secondInfo.expenditureTotal : 0}}万元
                  </p>
                  <p>中央：{{secondInfo.expenditureFundsCentral ? secondInfo.expenditureFundsCentral : 0}}万元</p>
                  <p>省级：{{secondInfo.expenditureFundsProvince ? secondInfo.expenditureFundsProvince : 0}}万元</p>
                  <p>地方：{{secondInfo.expenditureFundsLocal ? secondInfo.expenditureFundsLocal : 0}}万元</p>
                </div>
                <div class="all_number">
                  <p class="margin_lefts">
                    总支出占比：{{secondInfo.expenditureTotalPercentage ? secondInfo.expenditureTotalPercentage : 0}}%
                  </p>
                  <p>中央：{{secondInfo.expenditureFundsCentralPercentage ? secondInfo.expenditureFundsCentralPercentage : 0}}%</p>
                  <p>省级：{{secondInfo.expenditureFundsProvincePercentage ? secondInfo.expenditureFundsProvincePercentage : 0}}%</p>
                  <p>地方：{{secondInfo.expenditureFundsLocalPercentage ? secondInfo.expenditureFundsLocalPercentage : 0}}%</p>
                </div>
                <div><el-button class="pull-right export-btn" type="primary" @click="exportSecondTable">导出</el-button></div>
              </div> 
            </div>
          </el-form>
        </div>
      </div>

      <div class="nf-tabs-cont" v-show="currentNav === 1">
        <div class="nf-tabs-filter-form">
          <el-form :inline="true" class="filter-form">
            <el-form-item label="年份筛选">
              <el-select
                class="form-row-item"
                v-model="valueDate"
                placeholder="请选择年份"
              >
                <el-option label="所有年份" value=""></el-option>
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, i) in dateList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目地点">
              <el-select
                class="form-row-item"
                v-model="address"
                placeholder="请选择项目"
              >
                <el-option
                  label="所有地点"
                  value=""
                ></el-option>
                <el-option
                  label="三亚"
                  value="00f35bd651f54756be04544ec189fda6"
                ></el-option>
                <el-option
                  label="陵水"
                  value="012d31bd0b094ec7ad550b7db2aacc06"
                ></el-option>
                <el-option
                  label="乐东"
                  value="012556c1c9f548f9a0461a43b46a6382"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申报项目">
              <el-select
                class="form-row-item"
                v-model="itemType"
                placeholder="请选择项目"
              >
                <el-option label="所有项目" value=""></el-option>
                <el-option
                  :label="item.itemName"
                  :value="item.itemType"
                  v-for="(item, i) in projectList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            class="pull-right export-btn"
            type="primary"
            @click="exportTable"
            >导出</el-button
          >
        </div>
        <el-table
          :data="tableDatas"
          style="width: 100%"
          empty-text="暂无申请记录"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column label="项目名称" prop="itemName" align="center"></el-table-column>
          <el-table-column label="负责单位" prop="organizationName" align="center"></el-table-column>
          <el-table-column label="项目地点" prop="itemAddress" align="center"></el-table-column>
          <el-table-column label="申报年份" prop="declareTime" align="center"></el-table-column>
          <el-table-column label="下达资金(中央)万元" prop="releasedFundsCentral" align="center"></el-table-column>
          <el-table-column label="下达资金(省级)万元" prop="releasedFundsProvince" align="center"></el-table-column>
          <el-table-column label="下达资金(地方)万元" prop="releasedFundsLocal" align="center"></el-table-column>
          <el-table-column label="支出资金(中央)万元" prop="expenditureFundsCentral" align="center"></el-table-column>
          <el-table-column label="支出资金(省级)万元" prop="expenditureFundsProvince" align="center"></el-table-column>
          <el-table-column label="支出资金(地方)万元" prop="expenditureFundsLocal" align="center"></el-table-column>
          <el-table-column label="发布时间" prop="createdDate" align="center" width="100"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-tickets"
                @click="handleEdit(scope.$index, scope.row, 1)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <page-cut
          :total="total"
          @pageChange="pageChange"
          :currentPage="pageNum"
          :pageSize="pageSize"
        ></page-cut>
        <modal-detail
          v-model="dialogDetailVisible"
          :id="id"
          :userName="userName"
        ></modal-detail>
      </div>
    </div>
  </div>
</template>
<script>
import pageCut from "@/component/pagination/index.vue";
import modalDetail from "./modal-detail.vue";
import { downlodFile } from '@/utils/utils.js';
export default {
  data() {
    return {
      currentNav: 0,
      tabsNav: ["总体概览", "项目详情"],
      projectList: [
        { name: "生物育种专区项目", id: "1" },
        { name: "高标准农田项目", id: "2" },
        { name: "水利项目", id: "3" },
        { name: "制种大县项目", id: "4" },
        { name: "供地农民定金补贴项目", id: "5" },
        { name: "土地流转资金项目", id: "6" }
      ],
      dateList: ['2015', '2016', '2017', '2018', '2019', '2020', '2021'],
      firstStart: "",
      firstEnd: "",
      firstDate: "",
      firstType: "", // 项目1类型编号
      firstTypeText: '', // 项目2类型编号文字
      firstInfo: {},
      secondType: '', // 项目2类型编号
      secondTypeText: '', // 项目2类型编号文字
      secondStart: "",
      secondEnd: "",
      secondDate: "",
      secondInfo: {},
      secondAddress: '三亚',
      secondAddressId: "", // 项目地点id
      addressList: [
        { name: "三亚", id: "00f35bd651f54756be04544ec189fda6,1" },
        { name: "陵水", id: "012d31bd0b094ec7ad550b7db2aacc06" },
        { name: "乐东", id: "012556c1c9f548f9a0461a43b46a6382,1" }
      ],
      address: "", // 地区id
      valueDate: "", // 时间
      declareTimeStart: "", // 开始
      declareTimeEnd: "", // 结束
      itemType: "", // 项目类型编号
      id: "",
      dialogDetailVisible: false, // 查看详情
      userName: "", // 项目类型名称
      itemList: [
        "生物育种专区项目",
        "高标准农田项目",
        "水利项目",
        "制种大县项目",
        "供地农民定金补贴项目",
        "土地流转资金项目"
      ],
      pageSize: 10,
      pageNum: 1,
      total: 1,
      tableData: [], // 统计分析-总体概况列表
      tableDatas: [], // 统计分析-项目详情列表
      dataList: [],
      multipleSelection: [],
      echartsList: []
      
    };
  },
  components: {
    pageCut,
    "modal-detail": modalDetail
  },
  created() {
    this.getProjectData();
    this.secondAddressId = this.addressList[0].id;
    this.getData();
    this.getDatas();
    this.getFirstList();
    this.getSecondList();
    this.getEchartsList();
  },
  mounted() {
    // this.setReportEcharts(this.dataList);
  },
  methods: {
    downlodFile,
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    switchNav(index) {
      this.currentNav = index;
    },
    // 项目管理列表
    async getProjectData() {
      const api = this.$fetchApi.projectList.api;
      const data = await this.$fetchData.fetchPost({}, api, "");
      if (data.code === "200") {
        this.projectList = data.result || [];
      }
    },
    // 总体概况-第一个
    async getFirstList() {
      const params = {
        declareTime: this.firstDate,
        itemAddressId: "",
        itemType: this.firstType
      };
      const api = this.$fetchApi.getStatistical.api;
      const data = await this.$fetchData.fetchPost(params, api, "json");
      if (data.code == '200' && data.result) {
        this.firstInfo = data.result;
      } else {
        this.firstInfo = [];
      }
    },
    // 总体概况-第一个-时间选择
    firstDateBtn(val) {
      this.firstDate = val;
      this.getFirstList();
    },
    // 总体概况-第一个-项目申报
    projectBtn(val) {
      this.firstTypeText = this.projectList.filter(item => item.itemType == val)[0].itemName;
      this.getFirstList();
    },
    // 总体概况-第二个-项目申报
    projectSecondBtn(val) {
      if (val) {
        this.secondTypeText = this.projectList.filter(item => item.itemType == val)[0].itemName;
      } else {
        this.secondTypeText = '';
      }
      this.getSecondList();
      this.getEchartsList();
    },
    // 总体概况-第二个
    async getSecondList() {
      const params = {
        declareTime: this.secondDate,
        itemAddressId: this.secondAddressId,
        itemType: this.secondType
      };
      const api = this.$fetchApi.getStatistical.api;
      const data = await this.$fetchData.fetchPost(params, api, "json");
      if (data.code == '200') {
        this.secondInfo = {};
        if (data.result) {
          this.secondInfo = data.result;
        } else {
          this.secondInfo = {};
        }
      }
    },
    // 总体概况-第二个-时间选择
    secondDateBtn(val) {
      this.secondDate = val;
      this.getSecondList();
      this.getEchartsList();
    },
    // 总体概况-第二个-项目申报
    addressBtn(val) {
      if (val == '00f35bd651f54756be04544ec189fda6,1') {
        this.secondAddress = '三亚';
      } else if (val == '012d31bd0b094ec7ad550b7db2aacc06') {
        this.secondAddress = '陵水';
      } else if (val == '012556c1c9f548f9a0461a43b46a6382,1') {
        this.secondAddress = '乐东';
      } else {
        this.secondAddress = '';
      }
      this.getSecondList();
      this.getEchartsList();
    },
    // echarts数据列表
    async getEchartsList() {
      const params = {
        declareTime: this.secondDate,
        itemAddressId: this.secondAddressId,
        itemType: this.secondType
      };
      const api = this.$fetchApi.getEcharts.api;
      const data = await this.$fetchData.fetchPost(params, api, "json");
      if (data.code == '200' && data.result) {
        this.echartsList = data.result;
        this.echartsList.forEach((item, i) => {
          setTimeout(() => {
            this.firstLeftEchartsFun(this.echartsList[i], i);
            this.firstRightEchartsFun(this.echartsList[i], i);
          }, 500);
        });
      } else {
        this.echartsList = [];
        this.firstLeftEchartsFun();
        this.firstRightEchartsFun();
      }
    },

    // 搜索
    onSubmit() {
      this.pageNum = 1;
      this.getDatas();
    },
    handleEdit(index, el, i) {
      // 查看详情
      if (i === 1) {
        this.id = el.id;
        this.userName = el.itemName;
        this.dialogDetailVisible = true;
      }
    },
    // 统计分析-总体概况列表
    async getData() {
      const api = this.$fetchApi.statisticalList.api;
      const data = await this.$fetchData.fetchPost("", api);
      if (data.code === "200") {
        this.tableData = data.result || [];
      }
    },
    // 统计分析-项目详情列表
    async getDatas() {
      const params = {
        declareTime: this.valueDate,
        itemAddressId: this.address,
        itemType: this.itemType + '',
        pageSize: this.pageSize,
        pageNum: this.pageNum
      };
      const api = this.$fetchApi.statisticalLists.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code === '200') {
        this.tableDatas = data.result.list || [];
        this.total = data.result.total;
      }
    },
    pageChange(num) {
      this.pageNum = Number(num.page_index);
      this.getDatas();
    },
    // 导出-总体概况1
    async exportFirstTable() {
      const api = this.$fetchApi.exportExcelFirst.api;
      const data = await this.$fetchData.fetchPost({}, api + `?declareTime=${this.firstDate}&itemType=${this.firstType}`, 'arraybuffer');
      this.downlodFile(data, '总体概况统计一.xls');
    },
    // 导出-总体概况2
   async exportSecondTable() {
    //  if(!this.secondStart) {
    //     this.$message("请选择日期");
    //     return;
    //   }
      const api = this.$fetchApi.exportExcelSecond.api;
      const data = await this.$fetchData.fetchPost({}, api + `?declareTime=${this.secondDate}&itemAddressId=${this.secondAddressId}`, "arraybuffer");
      this.downlodFile(data, "总体概况统计二.xls");
    },
    // 导处列表表格
    async exportTable() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择要导出的数据");
        return;
      }
      const str = this.multipleSelection.reduce(function (prev, cur) {
        return prev + `ids=${cur.id}&`;
      }, '');
      const api = this.$fetchApi.exportExcelThird.api;
      const data = await this.$fetchData.fetchPost(
        {},
        `${api}?${str}`,
        "arraybuffer"
      );
      this.downlodFile(data, "项目详情.xls");
    },
    // 树状图
    firstLeftEchartsFun(msg, i) {
      // this.$refs[`firstLeftEcharts${ + '' + i}`]
      const myChart = echarts.init(document.getElementById(`firstLeftEcharts${ + '' + i}`));
      const option = {
        color: ['#4981C3', '#E18543'],
        legend: {},
        tooltip: {},
        grid: {
          top: 50,
          left: 5, // 调整这个属性
          right: 40,
          bottom: 40
        },
        dataset: {
          source: [
            ['product', '下达', '支出'],
            ['中央', msg.releasedFundsCentral, msg.expenditureFundsCentral],
            ['省级', msg.releasedFundsProvince, msg.expenditureFundsProvince],
            ['地方', msg.releasedFundsLocal, msg.expenditureFundsLocal]
          ]
        },
        xAxis: {type: 'category'},
        yAxis: {},
        series: [
          {type: 'bar'},
          {type: 'bar'}
        ]
      };
      myChart.setOption(option);
    },
    // 扇形图
    firstRightEchartsFun(msg, i) {
      const myChart = echarts.init(document.getElementById(`firstRightEcharts${ + '' + i}`));
      const option = {
        title: {
          text: '',
          x: 'center'
        },
        color: ['#4981C3', '#E18543', '#A7A39E'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '65%',
            center: ['50%', '45%'],
            // avoidLabelOverlap: true,
            data: [
              {name: '中央', value: msg.expenditureFundsCentral},
              {name: '省级', value: msg.expenditureFundsProvince},
              {name: '地方', value: msg.expenditureFundsLocal}
            ],
            itemStyle: {
              label: {
                show: true,
                position: 'inner',
                formatter: '{d}%', // 数值和百分比
                textStyle: {
                  fontSize: 16,
                  fontWeight: 'bolder'
                }
              },
              labelLine: {
                show: false // 显示饼状图上的文本时，指示线不显示，在第一个data时显示指示线
              }
            }
          }
        ]
      };
      myChart.setOption(option);
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .nf-tabs-nav {
  display: inline-block;
  background: #ffffff;
  width: 100% !important;
  box-shadow: 0 2px 3px 0px rgba(38, 90, 225, 0.15);

  &-label {
    display: inline-block;
    padding: 16px 32px;
    font-size: 16px;
    color: #333333;
    position: relative;
    cursor: pointer;

    &.is-active {
      color: #3380fe;
      border-bottom: 3px solid #3380fe;

      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #3380fe;
        box-shadow: 0 2px 3px 0px rgba(51, 128, 254, 0.7);
      }
    }
  }
}

.tab-content {
  max-width: 1200px;
  margin: 0 auto;
}

.breadcrumb {
  border: none;
  margin-bottom: 30px;
}

.form-row {
  width: 105px;

  &-date {
    width: 250px;
  }
}

.filter-form {
  padding: 25px 0 0 20px;
}

.el-form-item {
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}

.nf-tag {
  display: inline-block;
  padding: 4px 8px;
  line-height: 11px;
  font-size: 12px;
  color: #ffffff;
  border-radius: 10px;

  &-jinji {
    background: #f56c6c;
  }

  &-ji {
    background: #e6a23c;
  }

  &-yiban {
    background: #909399;
  }
}

.table-cont-title {
  margin-left: 10px;
  cursor: pointer;
}

.text_center {
  text-align: center;
}

/deep/ .red {
  color: red !important;
}

.pull-right {
  float: right;
}
.export-btn {
  margin-right: 90px;
}

.number_content {
  margin-bottom: 80px;
}

.all_number {
  display: inline-block;
  width: 30%;
  height: 55px;

  .margin_left {
    margin-left: 0px;
  }

  .margin_lefts {
    margin-left: 35px;
  }

  p {
    height: 50px;
    margin-left: 60px;
    line-height: 50px;
  }

  img {
    position: relative;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  
}

.other_number {
  display: flex;
  height: 45px;

  .other_number_left {
    margin-left: 105px;
    img {
      position: relative;
      top: 10px;
      width: 30px;
      height: 30px;
    }
  }
  .other_number_right {
    margin-left: 168px;
    img {
      position: relative;
      top: 10px;
      width: 30px;
      height: 30px;
    }
  }

  .other_number_rights {
    margin-left: 182px;
  }
}

.second_title {
  margin-left: 230px;
  height: 40px;
  line-height: 40px;
}

.margin-rights {
  margin-right: 10px;
}

.echarts_class {
  margin-top: 50px;
}

.firstEchartsClass {
  display: flex;
  margin-left: 20px;

  .firstLeftEchartsClass {
    width: 50%;

    .firstLeftEcharts {
      height: 400px;
      width: 100%;
    }
  }

  .firstRightEchartsClass {
    width: 50%;

    .firstRightEcharts {
      width: 100%;
      height: 350px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
  }

  .center {
    text-align: center;
    height: 40px;
  }

  .titles {
    font-weight: 700;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    margin-left: 20px;
  }

  .centers {
    text-align: center;
    height: 80px;
    margin-top: 20px;
  }

  
}

</style>
