<template>
  <div>
    <el-dialog :title="id ? '编辑项目' :'新增项目'" :visible.sync="dialogSecondFormVisible" center :close-on-click-modal="false" lock-scroll top="10vh">
      <el-form ref="form" :model="form" :rules="form" label-width="150px">
        <el-form-item label="项目负责单位" :rules="[{ message: '请输入项目负责单位', required: true }]"
          prop="itemName">
          <el-col :span="16">
            <el-input v-model="form.itemName" placeholder="请输入项目负责单位" maxlength="50" show-word-limit></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="项目地点" :rules="[{ message: '请选择项目地点', required: true }]"
          prop="itemReqs">
          <el-checkbox-group v-model="form.itemReqs">
            <el-checkbox :label="item.address" :name="item.address" v-for="(item, i) in form.list" :key="i"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="项目填报模板" :rules="[{ message: '请选择项目填报模板', required: true }]"
          prop="radio">
          <el-radio v-model="form.radio" label="1">填报模板1</el-radio>
          <span class="color_blue" @click="dialogFormImg = true">查看样例</span>
          <span></span>
        </el-form-item>
      </el-form>    
      <div slot="footer" class="dialog-footer">
        <el-button style="width:60%" type="primary" @click="onSubmit">提 交</el-button>
      </div>
    </el-dialog>
     <el-dialog title="查看样例" :visible.sync="dialogFormImg">
      <el-form :inline="true" class="filter-form">
        <img class="img_class" src="../../../assets/images/img_list.png" alt="">
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormImg = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:'modals-input',
  props: ['value','id'],
  data() {
    return {
      dialogSecondFormVisible: this.value,
      dialogFormImg: false,
      form: {
        itemName: '',
        itemReqs: [],
        radio: '1',
        addressList: [],
        valueData: '',
        projectList: [{}], // 添加项目列表
        list: [
          { address: "三亚", addressId: "00f35bd651f54756be04544ec189fda6,1" },
          { address: "陵水", addressId: "012d31bd0b094ec7ad550b7db2aacc06" },
          { address: "乐东", addressId: "012556c1c9f548f9a0461a43b46a6382,1" }
        ],
      }
    }
  },
  created() {
    if (this.id) {
      this.getDetails()
    }
  },
  methods:{
    // 根据id获取详情
    async getDetails() {
      const api = this.$fetchApi.projectDetail.api;
      const data = await this.$fetchData.fetchPost('', api + this.id);
      if (data.code == '200') {
        this.form.itemName = data.result.itemName;
        if (data.result.itemType == '3') {
          this.form.list = [
            { address: "乐亚片区", addressId: "1" },
            { address: "陵水片区", addressId: "012d31bd0b094ec7ad550b7db2aacc06" },
          ];
        } else {
          this.form.list = [
            { address: "三亚", addressId: "00f35bd651f54756be04544ec189fda6,1" },
            { address: "陵水", addressId: "012d31bd0b094ec7ad550b7db2aacc06" },
            { address: "乐东", addressId: "012556c1c9f548f9a0461a43b46a6382,1" }
          ];
        }
        data.result.addressResps.forEach(item => {
          return this.form.itemReqs.push(item.address)
        });
      }
    },
    // 提交
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.addressList = []
          this.form.itemReqs.forEach(item => {
            return this.form.addressList.push({
              address: item,
              addressId: item== '三亚' ? '00f35bd651f54756be04544ec189fda6' : 
                (item == '陵水' || item == '陵水片区' ? '012d31bd0b094ec7ad550b7db2aacc06' : (item == '乐东'
                ? '012556c1c9f548f9a0461a43b46a6382' : '1'))
            })
          })
          const params = {
            id: this.id,
            addressReqs: this.form.addressList,
            itemName: this.form.itemName, // 项目负责单位
            template: '1',
          }
          if (this.id) {
            const api = this.$fetchApi.updateProject.api;
            const data = await this.$fetchData.fetchPost(params, api, "json");
            if (data.code == '200') {
              this.dialogSecondFormVisible = false
              this.$parent.getSecondData();
              this.$message.success('编辑项目成功')
            } else {
              this.$message(data.message);
            }
          } else {
            const api = this.$fetchApi.addProject.api;
            const data = await this.$fetchData.fetchPost(params, api, "json");
            if (data.code == '200') {
              this.dialogSecondFormVisible = false
              this.$parent.getSecondData();
              this.$message.success('新增项目成功')
            } else {
              this.$message(data.message);
            }
          }
        } else {
          this.$message.error('请输入/选择必填项');
        }
      });
    },
  },
  watch:{
    value(newVal){
      this.dialogSecondFormVisible = newVal;
    },
    dialogSecondFormVisible(newVal){
      this.$emit('input', newVal);
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__wrapper{
  display: grid;
  place-items: center;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
/deep/.el-dialog {
  width: 960px!important;
}
/deep/.el-radio{
  width: 80px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px!important;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-dialog--center .el-dialog__body{
  padding: 10px 20px;
}
.el-input{
  width: calc(100% - 100px);
  margin-right:10px
}
.font-bold{
  font-weight: bold;
}
.el-form-item{
  margin-bottom: 10px;
} 
.el-tag{
  margin: 4px 10px;
}
/deep/.el-dialog {
  width: 600px;
}
/deep/.el-dialog--center .el-dialog__body{
  padding-bottom: 0px;
}

.margin-left {
  margin-left: 10px;
}

.margin-top {
  margin-top: 25px;
}
/deep/ .el-input {
  width: 100%;
}

.dialog-footer {
  margin-top: 50px;
}


.img_class {
  display: inline-block;
  width: 100%;
  height: 100%;;
}

.color_blue {
  color: #409EFF;
  cursor: pointer;
}

</style>