<template>
  <div>
    <el-dialog :title="userName" :visible.sync="dialogFormVisible" center :close-on-click-modal="false" lock-scroll top="10vh">
    <el-form ref="form" :model="form" :rules="form" label-width="150px">
      <el-form-item class="margin-top" label="项目负责单位" :rules="[{ message: '请输入项目负责单位', required: true }]"
        prop="organizationName">
        <el-col :span="16">
          <el-input v-model="form.organizationName" placeholder="请输入项目负责单位"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item class="margin-top" label="新增资金下达情况" prop="releasedFundsCentral" :rules="rules[`otherAreaType`]">
        <el-col :span="10" class="inline_block">
          <el-input v-model="form.releasedFundsCentral" type="number" placeholder="中央（万元）"></el-input>
          <span>万元</span>
        </el-col>
      </el-form-item>
      <el-form-item class="margin-top" prop="releasedFundsProvince" :rules="rules[`otherAreaType`]">
        <el-col :span="10" class="inline_block">
          <el-input v-model="form.releasedFundsProvince" type="number" placeholder="省级（万元）"></el-input>
          <span>万元</span>
        </el-col>
      </el-form-item>
      <el-form-item class="margin-top" prop="releasedFundsLocal" :rules="rules[`otherAreaType`]">
        <el-col :span="10" class="inline_block">
          <el-input v-model="form.releasedFundsLocal" type="number" placeholder="地方（万元）"></el-input>
          <span>万元</span>
        </el-col>
      </el-form-item>
      <el-form-item class="margin-top" label="新增资金支出情况" prop="expenditureFundsCentral" :rules="rules[`otherAreaType`]">
        <el-col :span="10" class="inline_block">
          <el-input v-model="form.expenditureFundsCentral" type="number" placeholder="中央（万元）"></el-input>
          <span>万元</span>
        </el-col>
      </el-form-item>
      <el-form-item class="margin-top" prop="expenditureFundsProvince" :rules="rules[`otherAreaType`]">
        <el-col :span="10" class="inline_block">
          <el-input v-model="form.expenditureFundsProvince" type="number" placeholder="省级（万元）"></el-input>
          <span>万元</span>
        </el-col>
      </el-form-item>
      <el-form-item class="margin-top" prop="expenditureFundsLocal" :rules="rules[`otherAreaType`]">
        <el-col :span="10" class="inline_block">
          <el-input v-model="form.expenditureFundsLocal" type="number" placeholder="地方（万元）"></el-input>
          <span>万元</span>
        </el-col>
      </el-form-item>
      <div class="margin-top" v-if="userName != '供地农民定金补贴项目'">
        <el-form-item class="margin-top" label="年度工作目标" :rules="[{ message: '请输入年度工作目标', required: true }]"
          prop="workObjectives">
          <el-input type="textarea" v-model="form.workObjectives" rows='5' show-word-limit></el-input>
        </el-form-item>
        <el-form-item class="margin-top" label="总工程进度百分比" :rules="rules[`percentageType`]"
          prop="projectSchedule">
          <el-col :span="8">
            <el-input v-model="form.projectSchedule" type="number" placeholder="请输入总工程进度百分比"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item class="margin-top" label="项目总体进展" :rules="[{ message: '请输入项目总体进展', required: true }]"
          prop="overallProgress">
          <el-input type="textarea" v-model="form.overallProgress" rows='5' show-word-limit></el-input>
        </el-form-item>
      </div>
      <!-- 已/未发放对象 -->
      <div v-else>
        <div v-for="item in yesObjectList" :key="item.id">
          <el-form-item label="已发放对象">
            <span>{{item.grantObject}}</span>
          </el-form-item>
          <el-form-item label="已发放面积">
            <span>{{item.grantArea}}</span>
          </el-form-item>
          <el-form-item label="已发放金额">
            <span>{{item.grantAmount}}</span>
          </el-form-item>
        </div>
        <p class="line_class" v-if="yesObjectList.length != 0"></p>
        <el-form-item label="已发放对象">
          <el-button class="margin-left" type="primary" @click="addYesObject" v-if="!id">+新增</el-button>
        </el-form-item>
        <div v-if="!isYesShow">
          <div v-for="(item, i) in form.yesObjectList" :key="i">
            <el-form-item label="已发放对象" class="margin-top" :rules="[{ message: '请选择区/县', required: true }]"
              :prop="`yesObjectList.${i}.areaName`">
              <el-col :span="6">
                <el-select v-model="form.itemAddress" placeholder="请选择项目地点" disabled>
                </el-select>
              </el-col>
              <el-col :span="6" class="margin-left">
                <el-select v-model="item.areaName" placeholder="请选择区/县" @change="areaYesBtn($event, i)" :disabled="!!id">
                  <el-option :label="itemYesOne.name" :value="itemYesOne.name" v-for="itemYesOne in areaYesList" :key="itemYesOne.id"></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="margin-left">
                <el-select v-model="item.villageName" placeholder="请选择村" @change="villageYesBtn($event, i)" :disabled="!!id">
                  <el-option :label="itemYesTwo.name" :value="itemYesTwo.name" v-for="itemYesTwo in villageYesList" :key="itemYesTwo.id"></el-option>
                </el-select>
              </el-col>
              <el-button class="margin-left" type="danger" @click="deleteYesBtn(i)" v-if="i !== 0 && !id || yesObjectList.length != 0">删除</el-button>
            </el-form-item>
            <el-form-item class="margin-top" label="已发放面积" :rules="[{ message: '请输入已发放面积', required: true }]"
              :prop="`yesObjectList.${i}.grantArea`">
              <el-col :span="15">
                <el-input v-model="item.grantArea" type="number" placeholder="请输入已发放面积"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item class="margin-top" label="已发放金额" :rules="[{ message: '请输入已发放金额', required: true }]"
              :prop="`yesObjectList.${i}.grantAmount`">
              <el-col :span="15">
                <el-input v-model="item.grantAmount" type="number" placeholder="请输入已发放金额"></el-input>
              </el-col>
            </el-form-item>
          </div>
        </div>
        <div v-for="item in notObjectList" :key="item.id">
          <el-form-item label="未发放对象">
            <span>{{item.grantObject}}</span>
          </el-form-item>
          <el-form-item label="存在争议地块">
            <span>{{item.landDispute}}</span>
          </el-form-item>
        </div>
        <p class="line_class" v-if="notObjectList.length != 0"></p>
        <el-form-item label="未发放对象" class="margin-top" >
          <el-button class="margin-left" type="primary" @click="addNotObject" v-if="!id">+新增</el-button>
        </el-form-item>
        <div v-if="!isNotShow">
          <div v-for="(item, i) in form.notObjectList" :key="i+'10'">
            <el-form-item label="未发放对象" class="margin-top" :rules="[{ message: '请选择区/县', required: true }]"
              :prop="`notObjectList.${i}.areaName`">
              <el-col :span="6">
                <el-select v-model="form.itemAddress" placeholder="请选择项目地点" disabled>
                </el-select>
              </el-col>
              <el-col :span="6" class="margin-left">
                <el-select v-model="item.areaName" placeholder="请选择区/县" @change="areaNotBtn($event, i)" :disabled="!!id">
                  <el-option :label="itemNotOne.name" :value="itemNotOne.name" v-for="itemNotOne in areaNotList" :key="itemNotOne.id"></el-option>
                </el-select>
              </el-col>
              <el-col :span="6" class="margin-left">
                <el-select v-model="item.villageName" placeholder="请选择村" @change="villageNotBtn($event, i)" :disabled="!!id">
                  <el-option :label="itemNotTwo.name" :value="itemNotTwo.name" v-for="itemNotTwo in villageNotList" :key="itemNotTwo.id"></el-option>
                </el-select>
              </el-col>
              <el-button class="margin-left" type="danger" @click="deleteNotBtn(i)" v-if="i !== 0 && !id || notObjectList.length != 0">删除</el-button>
            </el-form-item>
            <el-form-item class="margin-top" label="存在争议地块" :rules="[{ message: '请输入存在争议地块', required: true }]"
              :prop="`notObjectList.${i}.landDispute`">
              <el-col :span="15">
                <el-input v-model="item.landDispute" type="text" placeholder="请输入存在争议地块"></el-input>
              </el-col>
            </el-form-item>
          </div>
        </div>
      </div>
      <el-form-item class="margin-top" label="存在问题" :rules="[{ message: '请输入存在问题', required: true }]"
        prop="existProblem">
        <el-input type="textarea" v-model="form.existProblem" rows='5' show-word-limit></el-input>
      </el-form-item>
      <el-form-item class="margin-top" label="有关建议" :rules="[{ message: '请输入有关建议', required: true }]"
        prop="proposal">
        <el-input type="textarea" v-model="form.proposal" rows='5' show-word-limit></el-input>
      </el-form-item>
      <el-form-item class="margin-top" label="下一步计划" :rules="[{ message: '请输入下一步计划', required: true }]"
        prop="plan">
        <el-input type="textarea" v-model="form.plan" rows='5' show-word-limit></el-input>
      </el-form-item>
      <el-form-item class="margin-top" label="项目联系人" :rules="[{ message: '请输入项目联系人', required: true }]"
        prop="projectContact">
        <el-col :span="10">
          <el-input v-model="form.projectContact" placeholder="请输入项目联系人"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item class="margin-top" label="联系电话" :rules="rules[`telephoneType`]"
        prop="contactNumber">
        <el-col :span="10">
          <el-input v-model="form.contactNumber" type="number" placeholder="请输入联系电话"></el-input>
        </el-col>
      </el-form-item>
    </el-form>
  <el-divider ></el-divider>
  <div slot="footer" class="dialog-footer">
    <el-button style="width:60%" type="primary" @click="onSubmit">提 交</el-button>
  </div>
</el-dialog>
  </div>
</template>
<script>
import {  Message } from "element-ui";
import addressSelect from './address.vue';

const otherAreaType = (rule, value, callback) => {
  console.log(value)
  if (!value && value != 0) {
    callback(new Error('请输入'));
    return;
  }
  const reg = /^([\d]+(\.\d{1,2})?$)|^([1-9]+)$/;
  if (!reg.test(value)) {
    callback(new Error('不能为负数且小数不超过2位'));
    return;
  }
  callback();
};

const telephoneType = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入联系电话'));
    return;
  }
  const reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
  if (!reg.test(value)) {
    callback(new Error('电话格式不正确'));
    return;
  }
  callback();
};

const percentageType = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入百分比'));
    return;
  }
  const reg = /^(?:100|[1-9][0-9]?|0)$/;
  if (!reg.test(value)) {
    callback(new Error('百分比格式不正确'));
    return;
  }
  callback();
};

export default {
  name:'modal-input',
  props: ['value', 'id','userName', 'date', 'address'],
  components:{
    addressSelect,
  },
  
  data() {
    return {
      isContent: false, // 是否有数据
      dialogFormVisible: this.value,
      projectListType: [], // 获取项目选项列表
      cityId: '', // 市id
      areaYesList: [], // 区yes列表
      areaNotList: [], // 区no列表
      villageYesList: [], // 村列表
      villageNotList: [], // 村列表
      yesObjectList: [], // 已发放对象列表
      notObjectList: [], // 未发放对象列表
      isYesShow: false,
      isNotShow: false,
      expenditureFirstNum: '',
      expenditureSecondNum: '',
      expenditureThirdNum: '',
      releasedFirstNum: '',
      releasedSecondNum: '',
      releasedThirdNum: '',
      form: {
        declareTime: this.date,
        address: '',
        resource: '',
        yesObjectList: [{}], // 已发放对象列表
        notObjectList: [{}], // 未发放对象列表
        desc: '',
        projectList: [{}], // 添加项目列表
        contactNumber: "", // 联系电话
        existProblem: "", // 存在问题
        expendTotalNumber: '', // 支出总计
        expenditureFundsCentral: '', // 支出-中央
        expenditureFundsLocal: '', // 支出-地方
        expenditureFundsProvince: '', // 支出-省级
        itemAddress: this.address, // 项目地点
        itemAddressId: '', // 项目市县id
        itemName: "", // 项目类型名称
        itemType: "", // 项目类型编号
        nikeName: "", // 上报人
        organizationName: "", // 项目负责单位
        plan: "", // 下一步建议
        projectContact: "", // 项目联系人
        projectSchedule: '', // 总工程进度百分比
        workObjectives: '', // 年度工作目标
        overallProgress: '', // 项目总体进展
        proposal: "", // 有关建议
        releasedTotalNumber: '', // 发放总计
        releasedFundsCentral: '', // 中央-发放
        releasedFundsLocal: '', // 地方-发放
        releasedFundsProvince: '', // 省级-发放
        relevantReqList: [ // 项目申报补充说明
          {
            grantAmount: "", // 发放金额
            grantArea: "", // 发放面积
            grantObject: "", // 发放对象
            grantObjectId: "", // 发放的对象村id
            landDispute: "", // 有争议的地块
            projectSchedule: "", // 进度百分比
            title: "", // 标题内容
            type: "" // 类型 1.项目涉及内容 2.农民定金补贴已发放对象 3.农民定金补贴未发放对象
          }
        ]
      },
      rules: {
        otherAreaType: [{ validator: otherAreaType, required: true }],
        telephoneType: [{ validator: telephoneType, required: true }],
        percentageType: [{ validator: percentageType, required: true }]
      }
    }
  },
  // computed: {
  //   releasedTotalNumber() {
  //     // 下达总计
  //     this.form.releasedTotalNumber = +this.form.releasedFundsCentral + +this.form.releasedFundsLocal + +this.form.releasedFundsProvince;
  //     return +this.form.releasedFundsCentral + +this.form.releasedFundsLocal + +this.form.releasedFundsProvince;
  //   },
  //   expendTotalNumber() {
  //     // 支出总计
  //     this.form.expendTotalNumber =  +this.form.expenditureFundsCentral + +this.form.expenditureFundsLocal + +this.form.expenditureFundsProvince;
  //     if (this.form.releasedTotalNumber < this.form.expendTotalNumber) {
  //       console.log(this.form.expendTotalNumber)
  //       this.$message('超出下达资金，请核对您填写金额');
  //       this.form.expenditureFundsCentral = ''
  //       this.form.expenditureFundsLocal = ''
  //       this.form.expenditureFundsProvince = ''
  //     }
  //     return +this.form.expenditureFundsCentral + +this.form.expenditureFundsLocal + +this.form.expenditureFundsProvince;
  //   }
  // },
  async created() {
    await this.getProjectList()
    if (this.id) {
      this.getDetails()
    } else {
      this.getlastTime()
    }
    if (this.address == '三亚') {
      this.cityId = '00f35bd651f54756be04544ec189fda6'
    } else if (this.address == '陵水' || this.address == '陵水片区' ) {
      this.cityId = '012d31bd0b094ec7ad550b7db2aacc06'
    } else if (this.address == '乐东') {
      this.cityId = '012556c1c9f548f9a0461a43b46a6382'
    } else if (this.address == '乐亚片区') {
      this.cityId = '1'
    }
    if (this.userName == '供地农民定金补贴项目') {
      this.disposeAddressInfo(this.cityId)
    }
  },
  methods:{
    // 获取用户可选项目列表
    async getProjectList() {
      const api = this.$fetchApi.projectIsList.api;
      const data = await this.$fetchData.fetchPost('', api);
      if (data.code == '200') {
        this.projectListType = data.result;
      } else {
        this.$message(data.message);
      }
    },
    // 最近申报详情
    async getlastTime() {
      console.log(this.projectListType)
      let itemType = '';
      if (this.projectListType.length !== 0) {
        itemType = this.projectListType.filter(item => item.itemName == this.userName)[0].itemType
      }
      
      const params = {
        declareTime: this.date,
        itemAddressId: this.address == '三亚' ? '00f35bd651f54756be04544ec189fda6' : 
        (this.address == '陵水' || this.address == '陵水片区' ? '012d31bd0b094ec7ad550b7db2aacc06' : (this.address == '乐东'
        ? '012556c1c9f548f9a0461a43b46a6382' : '1')),
        itemType: itemType
      }
      const api = '/api/importantitem/backend/declare/lastTime';
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200' && data.result) {
        // this.form = data.result;
        this.isContent = true;
        this.form.organizationName = data.result.organizationName;
        this.form.releasedFundsCentral = data.result.releasedFundsCentral;
        this.form.releasedFundsLocal = data.result.releasedFundsLocal;
        this.form.releasedFundsProvince = data.result.releasedFundsProvince;
        this.form.expenditureFundsCentral = data.result.expenditureFundsCentral;
        this.form.expenditureFundsLocal = data.result.expenditureFundsLocal;
        this.form.expenditureFundsProvince = data.result.expenditureFundsProvince;
        this.expenditureFirstNum = data.result.expenditureFundsCentral,
        this.expenditureSecondNum = data.result.expenditureFundsProvince,
        this.expenditureThirdNum = data.result.expenditureFundsLocal,
        this.releasedFirstNum = data.result.releasedFundsCentral,
        this.releasedSecondNum = data.result.releasedFundsProvince,
        this.releasedThirdNum = data.result.releasedFundsLocal,
        this.form.existProblem = data.result.existProblem;
        this.form.proposal = data.result.proposal;
        this.form.plan = data.result.plan;
        this.form.projectContact = data.result.projectContact;
        this.form.contactNumber = data.result.contactNumber;
        this.form.workObjectives = data.result.workObjectives;
        this.form.projectSchedule = data.result.projectSchedule;
        this.form.overallProgress = data.result.overallProgress;
        this.cityId = data.result.itemAddressId;
        if (this.userName != '供地农民定金补贴项目') {
          this.form.projectList = data.result.relevantReqList
        } else {
          this.disposeAddressInfo(this.cityId)
          this.yesObjectList = data.result.relevantReqList.filter(item => item.type == '2')
          this.notObjectList = data.result.relevantReqList.filter(item => item.type == '3')
        }
      } else {
        this.isContent = false;
      }
    },
    async disposeAddressInfo(id) {
      // 获取地址信息
      const api = this.$fetchApi.getAddressList.api;
      const data = await this.$fetchData.fetchPost({}, api + id);
      if (data.code === '200') {
        this.areaYesList = data.result.children || [];
        this.areaNotList = data.result.children || [];
      }
    },
    // 已发放对象选择区/县
    areaYesBtn(val, i) {
      if (val) {
        this.areaYesList.filter(item => {
          if (item.name == val) {
            this.villageYesList = item.children
            this.form.yesObjectList[i].grantObject = this.form.itemAddress + '-' + item.name;
            this.form.yesObjectList[i].areaId = item.id
          }
        })
      }
      if (this.form.yesObjectList[i].villageName && val != this.form.yesObjectList[i].villageName) {
        this.form.yesObjectList[i].grantObjectId = '';
        this.form.yesObjectList[i].villageName = '';
      }
    },
    // 已发放对象选择村
    villageYesBtn(val, i) {
      this.villageYesList.filter(item => {
        if (item.name == val) {
          this.form.yesObjectList[i].grantObjectId = item.id;
        }
      })
      this.form.yesObjectList[i].grantObject = this.form.itemAddress+ '-' + this.form.yesObjectList[i].areaName + '-' + val;
      this.form.yesObjectList[i].villageName = val;
    },
    // 未发放对象选择区/县
    areaNotBtn(val, i) {
      if (val) {
        this.areaNotList.filter(item => {
          if (item.name == val) {
            this.villageNotList = item.children
            this.form.notObjectList[i].grantObject = this.form.itemAddress + '-' + item.name;
            this.form.notObjectList[i].areaId = item.id
          }
        })
      }
      if (this.form.notObjectList[i].villageName && val != this.form.notObjectList[i].villageName) {
        this.form.notObjectList[i].grantObjectId = '';
        this.form.notObjectList[i].villageName = '';
      }
    },
    // 未发放对象选择村
    villageNotBtn(val, i) {
      this.villageNotList.filter(item => {
        if (item.name == val) {
          this.form.notObjectList[i].grantObjectId = item.id;
        }
      })
      this.form.notObjectList[i].grantObject = this.form.itemAddress+ '-' + this.form.notObjectList[i].areaName + '-' + val
      this.form.notObjectList[i].villageName = val;
    },
    // 根据id获取详情
    async getDetails() {
      const api = this.$fetchApi.declareDetails.api;
      const data = await this.$fetchData.fetchPost('', api + this.id);
      if (data.code == '200')  {
        this.form = data.result;
        this.form.projectList = data.result.relevantReqList;
        this.cityId = data.result.itemAddressId;
        
        if (this.userName != '供地农民定金补贴项目') {
          this.form.projectList = data.result.relevantReqList
        } else {
          this.disposeAddressInfo(this.cityId)
          this.form.yesObjectList = data.result.relevantReqList.filter(item => item.type == '2')
          this.form.notObjectList = data.result.relevantReqList.filter(item => item.type == '3')
          this.setList()
        }
      }
    },
    // 回显设置
    setList() {
      this.form.yesObjectList.forEach(item => {
        item.areaName = item.grantObject.split('-')[1]
        item.villageName = item.grantObject.split('-')[2]
      })
      this.form.notObjectList.forEach(item => {
        item.areaName = item.grantObject.split('-')[1]
        item.villageName = item.grantObject.split('-')[2]
      })
    },
    // 新增已发放对象
    addYesObject() {
      this.form.yesObjectList.push({})
      this.isYesShow = false;
    },
    deleteYesBtn(i) {
      if (this.isContent) {
        this.$confirm('是否确定不增加数据？', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (i == 0) {
            this.isYesShow = true;
            this.form.yesObjectList = []
          } else {
            this.form.yesObjectList.splice(i, 1)
          }
        })
      } else {
        this.form.yesObjectList.splice(i, 1)
      }
    },
    // 新增未发放对象
    addNotObject() {
      this.form.notObjectList.push({})
      this.isNotShow = false;
    },
    deleteNotBtn(i) {
      if (this.isContent) {
        this.$confirm('是否确定不增加数据？', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (i == 0) {
            this.isNotShow = true;
            this.form.notObjectList = [];
          } else {
            this.form.notObjectList.splice(i, 1)
          }
        })
      } else {
        this.form.notObjectList.splice(i, 1)
      }
    },
    // 提交
    async onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.addProjectList()
        } else {
          this.$message.error('请输入/选择必填项');
        }
      });
    },
    // 新增/编辑接口
    async addProjectList() {
      const params =  {
        contactNumber: this.form.contactNumber, // 联系电话
        declareTime: this.form.declareTime, // 申报年月
        existProblem: this.form.existProblem, // 存在问题
        expenditureFundsCentral: this.form.expenditureFundsCentral, // 支出-中央
        expenditureFundsLocal: this.form.expenditureFundsLocal, // 支出-地方
        expenditureFundsProvince: this.form.expenditureFundsProvince, // 支出-省级
        itemAddress: this.form.itemAddress, // 项目地点 012556c1c9f548f9a0461a43b46a6382
        itemAddressId: this.form.itemAddress == '三亚' ? '00f35bd651f54756be04544ec189fda6' : 
        (this.form.itemAddress == '陵水' || this.form.itemAddress == '陵水片区' ? '012d31bd0b094ec7ad550b7db2aacc06' : (this.form.itemAddress == '乐东'
        ? '012556c1c9f548f9a0461a43b46a6382' : '1')), // 项目市县id
        itemName: this.userName, // 项目类型名称
        itemType: this.projectListType.filter(item => item.itemName == this.userName)[0].itemType, // 项目类型编号
        organizationName: this.form.organizationName, // 项目负责单位
        workObjectives: this.form.workObjectives, // 年度工作目标
        overallProgress:this.form.overallProgress, // 项目总体进展
        plan: this.form.plan, // 下一步建议
        projectContact: this.form.projectContact, // 项目联系人
        projectSchedule: this.form.projectSchedule, // 总工程进度百分比
        proposal: this.form.proposal, // 有关建议
        releasedFundsCentral: this.form.releasedFundsCentral, // 中央-发放
        releasedFundsLocal: this.form.releasedFundsLocal, // 地方-发放
        releasedFundsProvince: this.form.releasedFundsProvince, // 省级-发放
        relevantReqList: [],
      }
      if (this.userName != '供地农民定金补贴项目') {
        this.form.projectList.forEach(item => item.type = '1')
        params.relevantReqList = this.form.projectList
      } else {
        if (!this.isContent) {
          
        } else {
          this.form.yesObjectList = [...this.yesObjectList, ...this.form.yesObjectList]
          this.form.notObjectList = [...this.notObjectList, ...this.form.notObjectList]
        }
        this.form.yesObjectList.forEach(item => item.type = '2')
        this.form.notObjectList.forEach(item => item.type = '3')
        
        params.relevantReqList = [...this.form.yesObjectList, ...this.form.notObjectList]
      }
      // 新建下达支出验证
      if (+this.form.releasedFundsCentral < +this.form.expenditureFundsCentral) {
        this.$message('中央资金支出不能大于中央资金下达');
        this.form.expenditureFundsCentral = '';
        return
      }
      if (+this.form.releasedFundsProvince < +this.form.expenditureFundsProvince) {
        this.$message('省级资金支出不能大于省级资金下达');
        this.form.expenditureFundsProvince = '';
        return
      }
      if (+this.form.releasedFundsLocal < +this.form.expenditureFundsLocal) {
        this.$message('地方资金支出不能大于地方资金下达');
        this.form.expenditureFundsLocal = '';
        return
      }
      // 下达验证
      if (+this.form.releasedFundsCentral < +this.releasedFirstNum && this.isContent) {
        this.$message('中央资金下达不能小于上一次提交的数字');
        this.form.releasedFundsCentral = '';
        return
      }
      if (+this.form.releasedFundsProvince < +this.releasedSecondNum && this.isContent) {
        this.$message('省级资金下达不能小于上一次提交的数字');
        this.form.releasedFundsProvince = '';
        return
      }
      if (+this.form.releasedFundsLocal < +this.releasedThirdNum && this.isContent) {
        this.$message('地方资金下达不能小于上一次提交的数字');
        this.form.releasedFundsLocal = '';
        return
      }
      console.log(+this.form.expenditureFundsCentral)
      console.log(+this.expenditureFirstNum)
      // 支出验证
      if (+this.form.expenditureFundsCentral < +this.expenditureFirstNum && this.isContent) {
        this.$message('中央资金支出不能小于上一次提交的数字');
        this.form.expenditureFundsCentral = '';
        return
      }
      if (+this.form.expenditureFundsProvince < +this.expenditureSecondNum && this.isContent) {
        this.$message('省级资金支出不能小于上一次提交的数字');
        this.form.expenditureFundsProvince = '';
        return
      }
      if (+this.form.expenditureFundsLocal < +this.expenditureThirdNum && this.isContent) {
        this.$message('地方资金支出不能不能小于上一次提交的数字');
        this.form.expenditureFundsLocal = '';
        return
      }
      if (this.id) {
        params.id = this.id
        const api = this.$fetchApi.updateDeclare.api;
        const data = await this.$fetchData.fetchPost(params, api, "json");
        if (data.code == '200') {
          this.$message.success('编辑成功');
          this.dialogFormVisible = false
          this.$parent.getData();
          this.$refs.form.resetFields()
        }  
      } else {
        const api = this.$fetchApi.addProjectList.api;
        const data = await this.$fetchData.fetchPost(params, api, "json");
        if (data.code == '200') {
          this.$message.success('新增成功');
          this.dialogFormVisible = false
          this.$parent.getData();
          this.$refs.form.resetFields()
        }  
      }
    }
  },
  watch:{
    value(newVal){
      this.dialogFormVisible = newVal;
    },
    dialogFormVisible(newVal){
      this.$emit('input', newVal);
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__wrapper{
  display: grid;
  place-items: center;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
/deep/.el-dialog {
  width: 960px!important;
}
/deep/.el-radio{
  width: 80px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px!important;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-dialog--center .el-dialog__body{
  padding: 10px 20px;
}
.el-input{
  width: calc(100% - 100px);
  margin-right:10px
}
.font-bold{
  font-weight: bold;
}
.el-form-item{
  margin-bottom: 10px;
} 
.el-tag{
  margin: 4px 10px;
}
/deep/.el-dialog {
  width: 600px;
}
/deep/.el-dialog--center .el-dialog__body{
  padding-bottom: 0px;
}

.margin-left {
  margin-left: 10px;
}

.margin-top {
  margin-top: 25px;
}
/deep/ .el-input {
  width: 100%;
}

.inline_block {
  display: flex;

  span {
    display: inline-block;
    width: 50px;
  }
}

el-select /deep/ .select-popper {
  z-index: 88888 !important;
  top: auto !important;
  left: auto !important
}

.line_class {
  width:80%;
  height:0px;
  text-align:center;
  margin: 30px 80px;
  border:1px dashed #000;
}
</style>