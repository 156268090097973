<template>
  <div class="apply">
    <div
      v-for="(nav, index) in tabsNav"
      :key="index"
      class="nf-tabs-nav-label"
      :class="{ 'is-active': currentNav === index }"
      @click="switchNav(index, nav)"
    >
      <i class="icon icon-await"></i>
      <span>{{ nav.itemName }}</span>
    </div>
    <div class="nf-tabs-filter-form">
      <div class="nanfanregister-content-btn" style="padding:20px 10px;">
        <el-button type="primary" size="medium" @click="addDialog" icon="el-icon-plus">新增项目上报</el-button>
      </div>
      <el-form :inline="true" class="filter-form">
        <el-form-item label="年份筛选">
          <el-select class="form-row" v-model='valueData' placeholder="请选择年份">
            <el-option label="所有年份" value=""></el-option>
            <el-option :label="o" :value="o" v-for="(o, i) in dateList" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" style="width: 100%;" empty-text="暂无申请记录">
      <el-table-column label="项目名称" prop="itemName" align="center"></el-table-column>
      <el-table-column label="负责单位" prop="organizationName" align="center"></el-table-column>
      <el-table-column label="项目地点" prop="itemAddress" align="center"></el-table-column>
      <el-table-column label="申报年份" prop="declareTime" align="center"></el-table-column>
      <el-table-column label="下达资金(中央)万元" prop="releasedFundsCentral" align="center"></el-table-column>
      <el-table-column label="下达资金(省级)万元" prop="releasedFundsProvince" align="center"></el-table-column>
      <el-table-column label="下达资金(地方)万元" prop="releasedFundsLocal" align="center"></el-table-column>
      <el-table-column label="支出资金(中央)万元" prop="expenditureFundsCentral" align="center"></el-table-column>
      <el-table-column label="支出资金(省级)万元" prop="expenditureFundsProvince" align="center"></el-table-column>
      <el-table-column label="支出资金(地方)万元" prop="expenditureFundsLocal" align="center"></el-table-column>
      <el-table-column label="发布时间" prop="createdDate" align="center" width="100"></el-table-column>
      <el-table-column label="管理" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            icon="el-icon-tickets"
            @click="handleEdit(scope.$index, scope.row,0)"
            v-if="scope.row.status == 0"
          >发布</el-button>
          <el-button
            class="margin_top"
            size="mini"
            type="primary"
            icon="el-icon-tickets"
            @click="handleEdit(scope.$index, scope.row,1)"
          >查看</el-button>
          <el-button
            class="margin_top"
            size="mini"
            type="warning"
            @click="handleEdit(scope.$index, scope.row,2)"
            icon="el-icon-edit"
            v-if="scope.row.status == 0"
          >编辑</el-button>
          <el-button
            class="margin_top"
            size="mini"
            type="danger"
            @click="handleEdit(scope.$index, scope.row,3)"
            icon="el-icon-delete"
            v-if="scope.row.status == 0"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <page-cut :total="total" @pageChange="pageChange" :currentPage="pageNum" :pageSize="pageSize"></page-cut>
    <apply-form v-model="dialogFormVisible" @refresh="getSearch" :userName="projectName" :date="date" :address="address" :id="id" v-if="dialogFormVisible"></apply-form>
    <modal-detail v-model="dialogDetailVisible" :id="id" :userName="projectName"></modal-detail>
    <el-dialog title="请选择上报的项目" :visible.sync="dialogIsFormVisible" v-if="dialogIsFormVisible">
      <el-form :inline="true" class="filter-form">
        <el-form-item label="申报年份">
          <el-select v-model='date' placeholder="请选择申报年份" :popper-append-to-body="false" popper-class="select-popper">
            <el-option :label="item" :value="item" v-for="(item,i) in dateList" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <div>
          <el-form-item label="项目地点">
            <el-select v-model='address' placeholder="请选择项目地点" :popper-append-to-body="false" popper-class="select-popper">
              <el-option :label="item.address" :value="item.address" v-for="(item,i) in addressType" :key="i"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogIsFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBtn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import applyForm from './apply-form.vue';
import modalDetail from './modal-detail.vue';
import pageCut from "@/component/pagination/index.vue";
export default {
  name:'apply',
  data(){
    return {
      tabsNav: [],
      currentNav: 0,
      id:'',
      valueData: '', // 选中时间
      declareTimeStart: "", // 开始时间
      declareTimeEnd: "", // 结束时间
      dateList: ['2015', '2016', '2017', '2018', '2019', '2020', '2021'],
      itemAddressId: "",
      tableData:[],
      addressType: [], // 弹框项目地点
      address: '', // 弹框选中项目地点
      date: '', //  弹框选中项目地点
      dialogFormVisible: false, 
      dialogDetailVisible: false,
      dialogIsFormVisible: false,
      pageSize: 10,
      pageNum: 1,
      total:1,
      options:[],
      userInfo:{},
      projectName: '' // 项目类型名称
    }
  },
  components:{
    'apply-form': applyForm,
    'modal-detail': modalDetail,
     pageCut
  },
  created(){
    this.getLisType();
    const year = new Date().getFullYear()
    let arr = []
    console.log(year)
    for  (let item = 2015; item <= year; item ++){
      arr.push(item)
    }
    this.dateList = arr
  },
  mounted() {
  },
  methods:{
    switchNav(index, item) {
      console.log(item)
      this.currentNav = index;
      this.projectName = item.itemName;
      this.addressType = item.addressList;
      this.getData()
    },
    // 列表
    async getData() {
      let itemType = '';
      if (this.tabsNav.length !== 0) {
        itemType = this.tabsNav.filter(item => item.itemName == this.projectName)[0].itemType;
      }
      const params = {
        declareTime: this.valueData,
        itemAddressId: '',
        itemType: itemType, 
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      const api = this.$fetchApi.PersonalList.api;
      const data = await this.$fetchData.fetchPost(params, api, "json");
      if (data.code === "200") {
        this.tableData = data.result.list || [];
        this.total = data.result.total;
      }
    },
    // 获取用户可选项目列表
    async getLisType() {
      const api = this.$fetchApi.projectIsList.api;
      const data = await this.$fetchData.fetchPost('', api);
      if (data.code == '200') {
        this.tabsNav = data.result;
        this.projectName = data.result[0].itemName;
        this.addressType = data.result[0].addressList;
        this.getData();
      } else {
        this.$message(data.message);
      }
    },
    handleEdit(index,el,i) {
      // 查看详情
      if( i === 0 ) {
        // 发布
        this.$confirm('您确定要发布？', '发布项目', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.release(el.id);
        })
      }else if( i === 1 ) {
        this.id = el.id;
        this.projectName = el.itemName;
        this.dialogDetailVisible = true;
      }else if( i === 2 ) {
        this.id = el.id;
        this.projectName = el.itemName;
        this.dialogFormVisible = true;
      }else if( i === 3 ) {
        // 删除
        this.$confirm('您确定要删除？', '删除项目', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteApply(el.id);
        })
      }
    },
    pageChange(num){
      this.pageNum =Number(num.page_index);
      this.getData();
    },
    getSearch(type) {
      if(type === '1'){
        this.filterForm.forEach(item => {
          item.value = "";
        })
      }
      this.pageNum = 1;
      this.getData();
    },
    // 新增
    async addDialog() {
      if (this.projectName) {
        this.dialogIsFormVisible = true;
        this.id = '';
        this.date = '';
        this.address = '';
      } else {
        this.$message('用户不可申报项目');
      }
    },
    // 发布
    async release(id) {
      const api = this.$fetchApi.release.api;
      const data = await this.$fetchData.fetchPost({}, api + id);
      if (data.code !== '200') {
        this.$message.error(data.message);
        return false;
      }
      this.$message.success({message:'发布成功'});
      this.pageNum = 1;
      this.getData();
    },
    // 删除
    async deleteApply(id) {
      const api = this.$fetchApi.declareDeletes.api;
      const data = await this.$fetchData.fetchPost({}, api + id);
      if(data.code !== '200'){
        this.$message.error(data.message);
        return false;
      }
      this.$message.success({message:'删除成功'});
      this.pageNum = 1;
      this.getData();
    },
    // 弹框确认
    submitBtn() {
      if (this.date && this.address) {
        this.dialogIsFormVisible = false;
        this.dialogFormVisible = true;
      } else {
        this.$message.error('请先选择申报年份和项目地点');
      }
    }
  },
}
</script>
<style lang="less" scoped>
/deep/ .nf-tabs-nav {
  display: inline-block;
  background: #ffffff;
  width: 100% !important;
  box-shadow: 0 2px 3px 0px rgba(38, 90, 225, 0.15);

  &-label {
    display: inline-block;
    padding: 16px 25px;
    font-size: 16px;
    color: #333333;
    position: relative;
    cursor: pointer;

    &.is-active {
      color: #3380fe;
      border-bottom: 3px solid #3380fe;

      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #3380fe;
        box-shadow: 0 2px 3px 0px rgba(51, 128, 254, 0.7);
      }
    }
  }
}

.overflow-text{
  overflow: hidden;/*超出部分隐藏*/
  white-space: nowrap;/*不换行*/
  text-overflow:ellipsis;/*超出部分文字以...显示*/
}
.reCar {
  .el-input{
    width: calc(100% - 100px);
    margin-right:10px
  }
}
.apply{
  background: rgba(249, 251, 255, 0.99);
  min-height: 400px;
  .filter-form{
    padding: 10px 0px 0px 10px;
  }
  &-content {
    box-sizing: border-box;
    padding: 0 20px;
    &-title {
      position: relative;
      height: 60px;
      line-height: 60px;
      text-indent: 20px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -4px;
        display: block;
        width: 8px;
        height: 8px;
        background: rgba(51, 128, 254, 1);
        border-radius: 50%;
      }
    }
    /deep/ .el-table td {
      border: none;
      padding: 2px 0;
      // margin: 5px 0;
      // background-color:#fff;
      // box-sizing: border-box;
    }
    &-btn {
      text-align: center;
      height: 150px;
      line-height: 50px;
    }
    &-item {
      width: 80%;
      margin: 10px auto;
      text-align: center;
      span {
        margin-right: 35px;
        font-size: 18px;
      }
      /deep/ .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }
      /deep/ .el-icon-arrow-down {
        font-size: 16px;
      }
    }
  }
}

.margin_top {
  margin-top: 8px;
  margin-left: 0;
}

el-select /deep/ .select-popper {
  z-index: 8888 !important;
  top: auto !important;
  left: auto !important
}
</style>