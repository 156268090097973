<template>
  <div>
    <div class="nf-tabs-content">
      <div class="nf-tabs-cont" v-if="isShow">
        <div class="nf-tabs-filter-form" v-for="(item, i) in infoList" :key="i">
          <p class="title_class">项目{{i + 1}}：{{item.itemName}}</p>
          <el-form :inline="true" class="filter-form">
            <el-form-item label="年份筛选">
              <el-select
                class="form-row-item"
                v-model="item.firstDate"
                placeholder="请选择年份"
                @change="firstDateBtn($event, i, item)"
              >
                <el-option label="所有年份" value=""></el-option>
                <el-option
                  :label="item"
                  :value="item"
                  v-for="(item, i) in dateList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目地点">
              <el-select
                class="form-row-item"
                v-model="item.addressType"
                placeholder="请选择项目地点"
                @change="projectBtn($event, i, item)"
              >
                <el-option label="所有地点" value=""></el-option>
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, i) in addressList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="number_content" v-if="loading">
              <div class="all_number">
                <p class="margin_left">
                  <img class="margin-rights" src="../../../assets/images/officialDocument/expenditure.png" alt=""/>
                  总下达资金：{{item.firstInfo.releasedTotal ? item.firstInfo.releasedTotal : 0}}万元
                </p>
                <p>中央：{{item.firstInfo.releasedFundsCentral ? item.firstInfo.releasedFundsCentral : 0 }}万元</p>
                <p>省级：{{item.firstInfo.releasedFundsLocal ? item.firstInfo.releasedFundsLocal : 0}}万元</p>
                <p>地方：{{item.firstInfo.releasedFundsProvince ? item.firstInfo.releasedFundsProvince : 0}}万元</p>
              </div>  
              <div class="all_number">
                <p class="margin_left">
                  <img class="margin-rights"
                    src="../../../assets/images/officialDocument/income.png"
                    alt=""
                  />总支出资金：{{item.firstInfo.expenditureTotal ? item.firstInfo.expenditureTotal : 0}}万元
                </p>
                <p>中央：{{item.firstInfo.expenditureFundsCentral ? item.firstInfo.expenditureFundsCentral : 0}}万元</p>
                <p>省级：{{item.firstInfo.expenditureFundsLocal ? item.firstInfo.expenditureFundsLocal : 0}}万元</p>
                <p>地方：{{item.firstInfo.expenditureFundsProvince ? item.firstInfo.expenditureFundsProvince : 0}}万元</p>
              </div>
              <div class="all_number">
                <p class="margin_lefts">
                  总支出占比：{{item.firstInfo.expenditureTotalPercentage ? item.firstInfo.expenditureTotalPercentage : 0}}%
                </p>
                <p>中央：{{item.firstInfo.expenditureFundsCentralPercentage ? item.firstInfo.expenditureFundsCentralPercentage : 0}}%</p>
                <p>省级：{{item.firstInfo.expenditureFundsProvincePercentage ? item.firstInfo.expenditureFundsProvincePercentage : 0}}%</p>
                <p>地方：{{item.firstInfo.expenditureFundsLocalPercentage ? item.firstInfo.expenditureFundsLocalPercentage : 0}}%</p>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <div v-else class="notClass">暂无统计数据</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      loading: false,
      infoList: [],
      dateList: ['2015', '2016', '2017', '2018', '2019', '2020', '2021'],
      firstDate: "",
      addressType: "", // 地区id
      addressList: [
        { name: "三亚", id: "00f35bd651f54756be04544ec189fda6,1" },
        { name: "陵水", id: "012d31bd0b094ec7ad550b7db2aacc06" },
        { name: "乐东", id: "012556c1c9f548f9a0461a43b46a6382,1" },
      ],
      typeList: []
    };
  },
  created() {
    this.getLisType()
  },
  mounted() {
  },
  methods: {
    // 获取用户可选项目列表
    async getLisType() {
      const arr = []
      const api = this.$fetchApi.projectIsList.api;
      const data = await this.$fetchData.fetchPost('', api);
      if (data.code == '200' && data.result) {
        this.isShow = true;
        this.loading = false
        this.infoList = data.result;
        data.result.forEach((item, i) => {
          arr.push(this.getFirstList('', '', item.itemType, i))
        })
        Promise.all(arr).then(() => {
          this.loading = true
        })
      } else {
        this.isShow = false
        this.infoList = []
      }
    },
    async getFirstList(time, address, type, i) {
      const params = {
        declareTime: time,
        itemAddressId: address,
        itemType: type,
      };
      const api = this.$fetchApi.getStatistical.api;
      const data = await this.$fetchData.fetchPost(params, api, "json");
      if (data.code == '200' && data.result) {
        this.infoList[i].firstInfo = data.result;
      } else {
        this.infoList[i].firstInfo = {};
      }
      this.loading = false
      return data
    },
    // 总体概况时间选择
    firstDateBtn(val, i, item) {
      this.getFirstList(val, item.addressType ? item.addressType : '', item.itemType, i).then(() => {
        this.loading = true
      });
    },
    // 总体概况项目地点
    projectBtn(val, i, item) {
      this.getFirstList(item.firstDate ? item.firstDate : '', val, item.itemType, i).then(() => {
        this.loading = true
      });;
    },
  },
};
</script>
<style lang="less" scoped>
.form-row {
  width: 105px;

  &-date {
    width: 250px;
  }
}

.filter-form {
  padding: 25px 0 0 20px;
}

.el-form-item {
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}

.number_content {
  margin-bottom: 80px;
}

.all_number {
  display: inline-block;
  width: 30%;
  height: 55px;

  .margin_left {
    margin-left: 0px;
  }

  .margin_lefts {
    margin-left: 35px;
  }

  p {
    height: 50px;
    margin-left: 60px;
    line-height: 50px;
  }

  img {
    position: relative;
    top: 10px;
    width: 30px;
    height: 30px;
  }
  
}


.margin-rights {
  margin-right: 10px;
}

.title_class {
  height: 50px;
  line-height: 60px;
}

.notClass {
  height: 80px;
  line-height: 80px;
  margin-left: 40px;
}

</style>
