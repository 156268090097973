<script>
/* COMPONENT DOCUMENT
 * author: liyulong
 * date: 2019/10/08
 * desc: 三级联动 - 省市区地址选择
 */

import Vue from 'vue';
import {  Select, Option } from 'element-ui';
Vue.use(Select);
Vue.use(Option);

export default {
  name: 'province-city-district',
  props: {
    address: { type: Object, default: () => {} },
    isEdit: { type: Boolean, default: () => true},
    isRefresh:{ type: Boolean, default: () => false}
  },

  data() {
    return {
      columns: [
        {
          values: [],
          className: 'column1',
          defaultIndex: 0
        },
        {
          values: [],
          className: 'column2',
          defaultIndex: 0
        },
        {
          values: [],
          className: 'column3',
          defaultIndex: 0
        }
      ],
      address_info: [], // 三级地址信息
      address_show: false, // 地址选择

      provice:  {
        id: "e851a856406a4607a9ef1e1253b25e45",
        name: "海南省"
      },
      city: '',
      area: '',
      proviceList: [],
      districtList: [],
      areaList: [],
      message: '',
      val: [],
      detailVal:'',
      isFirst: true,
      disabled: true
    };
  },
  created() {
    this.disposeAddressInfo()
  },
  methods: {
    // 处理地址信息 去掉一二三层的全部，并默认显示第一层数据
    async disposeAddressInfo() {
      // 获取地址信息
      const api = this.$fetchApi.addressInfo.api;
      const data = await this.$fetchData.fetchPost({}, api);
      if (data.code === '200') {
        this.address_info = data.result.children || [];
        this.address_info.shift();
        this.$set(this.columns[0], 'values', this.address_info);
        this.choseProvince()
      }
    },
    resetAddress(){
      this.columns.forEach(item=>{
        item.values = [];
      })
      this.detailVal = "";
    },
    /** 根据省市区id查询对应的文字， 且默认展示对应的省市区 */
    getAddressName() {
      let province_index = 0;
      let city_index = 0;
      this.address_info.forEach((item, index) => {
        if (item.id === this.address.provinceId) {
          province_index = index;
          this.provice = item;
          this.$set(this.columns[0], 'values', this.address_info);
          // this.$set(this.columns[0], 'defaultIndex', index);
          return false;
        }
      });
      if (this.address_info[province_index]) {
        this.address_info[province_index].children.forEach((item, index) => {
          if (item.id === this.address.cityId) {
            city_index = index;
            this.city = item;
            this.$set(
              this.columns[1],
              'values',
              this.address_info[province_index].children
            );
            // this.$set(this.columns[1], 'defaultIndex', index);
            return false;
          }
        });
        this.address_info[province_index].children[city_index].children.forEach(
          (item) => {
            if (item.id === this.address.districtId) {
              this.area = item;
              this.$set(
                this.columns[2],
                'values',
                this.address_info[province_index].children[city_index].children
              );
              // this.$set(this.columns[2], 'defaultIndex', index);
              return false;
            }
          }
        );
      }
    },
    choseProvince:function() {
      const provice = this.columns[0].values;
      // for (var index in provice) {
      //   if (e.id === provice[index].id) {
          // provice[index].children.shift(); // 去掉全部选项
          this.$set(this.columns[1], 'values', provice[0].children);
          // console.log(this.columns[1])
          // this.city = '';
          // this.area = '';
          this.val[0] = {
            id: provice[0].id,
            name: provice[0].name
          } 
      //   }
      // }
    },
      // 选市
    choseCity:function(e) {
      const values = this.columns[1].values;
      for (var index in values) {
        if (e.id === values[index].id) {
          this.district = values[index]
          values[index].children.shift();
          this.$set(this.columns[2], 'values', values[index].children);
          this.area = '';
          this.val[1] = {
            id: values[index].id,
            name: values[index].name
          } 
        }
      }
      this.$emit('addressInfo', this.val);
    },
      // 选区
    choseBlock:function(e) {
      const values = this.columns[2].values;
      for (var index in values) {
        if (e.id === values[index].id) {
          this.area = values[index];
          this.val[2] = {
            id: values[index].id,
            name: values[index].name
          } 
        }
      }
      this.$emit('addressInfo', this.val);
    },
    changeDetail(){
       this.$emit('changeDetailAddress', this.detailVal);
    }
  },

  watch: {
    address: {
      async handler(val) {
        if (val) {
          this.disabled = val.disabled;
          if (this.isFirst) {
            await this.disposeAddressInfo();
          }
          if (val.provinceId && val.cityId && val.districtId) {
            this.getAddressName();
          }
        }
      },
      immediate: true
    },
    isRefresh(val){
      if(val) {
        this.resetAddress();
        this.isRefresh = false;
      } 
    }
  }
};
</script>

<template>
  <div class="province_city_district flex">
    <div class='areaItem'>
    <el-select
      v-model="provice"
      @change="choseProvince" 
      value-key="name"
      disabled
      placeholder="省级地区">
      <el-option
        v-for="item in columns[0].values"
        :key="item.id"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
  </div>
  <div class='areaItem'>
    <el-select
      v-model="city"
      @change="choseCity"
      :disabled = 'disabled'
      value-key="name"
      placeholder="市级地区">
      <el-option
        v-for="item in columns[1].values"
        :key="item.id"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
  </div>
    <div class='areaItem'>
      <el-select
      v-model="area"
      @change="choseBlock"
      :disabled = 'disabled'
      value-key="name"
      placeholder="区级地区">
      <el-option v-for="item in columns[2].values"
        :key="item.id"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
    </div>

    <div class='areaItem'>
       <el-input  placeholder ="请输入详细地址" @blur="changeDetail" v-model="detailVal" autocomplete="off"></el-input>
    </div>
  </div>
</template>

<style lang="less" scoped>
// @import '~@/assets/css/var';

.province_city_district {
  .areaItem{
    /deep/  .el-input__inner{
      width: 160px;
    }
    /deep/ .el-select{
      width:160px;
      margin-right:20px;
    }
    /deep/ .el-input{
      width:160px;
    }
  }
}
</style>
