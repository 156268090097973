<template>
  <div class="modal-detail">
    <el-dialog  :title="userName"
      :visible.sync="dialogDetailVisible" center lock-scroll  >
      <el-form ref="form" :model="form" :rules="form" label-width="150px">
        <el-form-item label="申报年份">
          <span>{{form.declareTime}}</span>
        </el-form-item>
        <el-form-item label="项目地点">
          <span>{{form.itemAddress}}</span>
        </el-form-item>
        <el-form-item label="项目负责单位">
          <span>{{form.organizationName}}</span>
        </el-form-item>
        <el-form-item label="新增资金下达情况">
          <span class="magin-right">中央{{form.releasedFundsCentral}}万元</span>
          <span class="magin-right">省级{{form.releasedFundsLocal}}万元</span>
          <span>地方{{form.releasedFundsProvince}}万元</span>
        </el-form-item>
        <el-form-item label="新增资金支出情况">
          <span class="magin-right">中央{{form.expenditureFundsCentral}}万元</span>
          <span class="magin-right">省级{{form.expenditureFundsLocal}}万元</span>
          <span>地方{{form.expenditureFundsProvince}}万元</span>
        </el-form-item>
        <div v-if="userName != '供地农民定金补贴项目'">
          <el-form-item label="年度工作目标">
            <span>{{form.workObjectives}}</span>
          </el-form-item>
          <el-form-item label="总工程进度百分比">
            <span>{{form.projectSchedule}} %</span>
          </el-form-item>
          <el-form-item label="项目总体进展">
            <span>{{form.overallProgress}}</span>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="已发放对象:"></el-form-item>
          <div v-for="item in yesObjectList" :key="item.id">
            <el-form-item label="已发放对象">
              <span>{{item.grantObject}}</span>
            </el-form-item>
            <el-form-item label="已发放面积">
              <span>{{item.grantArea}}</span>
            </el-form-item>
            <el-form-item label="已发放金额">
              <span>{{item.grantAmount}}</span>
            </el-form-item>
          </div>
          <el-form-item label="未发放对象:"></el-form-item>
          <div v-for="item in notObjectList" :key="item.id">
            <el-form-item label="未发放对象">
              <span>{{item.grantObject}}</span>
            </el-form-item>
            <el-form-item label="存在争议地块">
              <span>{{item.landDispute}}</span>
            </el-form-item>
          </div>
          
        </div>
        <el-form-item label="存在问题">
          <span>{{form.existProblem}}</span>
        </el-form-item>
        <el-form-item label="有关建议">
          <span>{{form.proposal}}</span>
        </el-form-item>
        <el-form-item label="下一步计划">
          <span>{{form.plan}}</span>
        </el-form-item>
        <el-form-item label="项目联系人">
          <span>{{form.projectContact}}</span>
        </el-form-item>
        <el-form-item label="联系电话">
          <span>{{form.contactNumber}}</span>
        </el-form-item>
      </el-form>  
    </el-dialog>
  </div>
</template>
<script>
import addressSelect from './address.vue';
export default {
  props: {
    id:{ type: String },
    userName:{ type: String },
    value:{ type: Boolean ,default: ()=>false },
    showDetail:{ type: Boolean ,default:()=> true },
    isApproval:{ type: Boolean, default: ()=> false},
    isReturnCar:{ type: Boolean, default: ()=> false}
  },
  components:{
    addressSelect,
  },
  name:'modal-input',
  data() {
    return {
      dialogDetailVisible: this.value,
      formLabelWidth: '180px',
      form: {},
      contentList: [], // 
      yesObjectList: [],
      notObjectList: []
    }
  },
  created() {
    if (this.id) {
      this.initData()
    }
  },
  mounted() {
  },
  methods:{
    async initData() {
      const api = this.$fetchApi.declareDetails.api;
      const data = await this.$fetchData.fetchPost('', api + this.id);
      if (data.code == '200') {
        this.form = data.result;
        this.contentList = data.result.relevantReqList.filter(item => item.type == '1')
        this.yesObjectList = data.result.relevantReqList.filter(item => item.type == '2')
        this.notObjectList = data.result.relevantReqList.filter(item => item.type == '3')
      }
    }
  },
  watch:{
    value(newValue) {
      this.dialogDetailVisible = newValue;
    },
    id(newValue) {
      if(!newValue) {
        return false;
      } else {
        this.initData();
      }
    },
    dialogDetailVisible(newValue){
      this.$emit('input', newValue);
      this.initData();
    },
  }
}
</script>
<style lang="less" scoped>
  /deep/.el-dialog {
    width: 960px!important;
  }
  .magin-right {
    margin-right: 15px;
  }
</style>