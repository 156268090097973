<template>
  <div>
    <el-dialog :title="id ? '编辑申报账号' :'新增申报账号'" :visible.sync="dialogFormVisible" center :close-on-click-modal="false" lock-scroll top="10vh">
      <el-form ref="form" :model="form" :rules="form" label-width="150px">
        <el-form-item label="项目负责单位" :rules="[{ message: '请输入项目负责单位', required: true }]"
        prop="organizationName">
          <el-col :span="16">
            <el-input v-model="form.organizationName" placeholder="请输入项目负责单位" maxlength="50" show-word-limit></el-input>
          </el-col>
        </el-form-item>
        <el-form-item class="margin-top" label="上报人" :rules="[{ message: '请输入上报人', required: true }]"
        prop="nikeName">
          <el-col :span="16">
            <el-input v-model="form.nikeName" placeholder="请输入上报人" maxlength="20" show-word-limit></el-input>
          </el-col>
        </el-form-item>
        <el-form-item class="margin-top" label="绑定上报账号" :rules="[{ message: '请输入绑定上报账号', required: true }]"
        prop="userName">
          <el-col :span="16">
            <el-input v-model="form.userName" type="number" @blur="userNameCheck" placeholder="请输入绑定上报账号"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item class="margin-top" label="新增绑定项目">
          <el-button class="margin-left" type="primary" @click="addProject" v-if="!id">+增加</el-button>
        </el-form-item>
        <div class="margin-top" v-for="(item, index) in form.projectList" :key="index">
          <el-form-item class="margin-top" :label="'项目' + (index + 1) + ':'">
            <el-button class="margin-left" type="danger" @click="deleteBtn(index, item)" v-if="index !== 0 && !id">删除</el-button>
          </el-form-item>
          <el-form-item class="margin-top" label="项目名称" :rules="[{ message: '请选择项目名称', required: true }]"
              :prop="`projectList.${index}.itemName`">
            <el-select class="form-row" v-model='item.itemName' placeholder="请选择项目名称" @change="dateSelect($event, index)"
             :popper-append-to-body="false" popper-class="select-popper" :disabled="!!id">
              <el-option :label="o.itemName" :value="o.itemName" v-for="(o, i) in form.list" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="margin-top" label="项目地点" :rules="[{ message: '请选择项目地点', required: true }]"
              :prop="`projectList.${index}.addressList`">
            <el-checkbox-group v-model="item.addressList">
              <el-checkbox :label="k.address" :name="k.addressId" v-for="(k, i) in item.addressListType" :key="i"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>    
      <div slot="footer" class="dialog-footer">
        <el-button style="width:60%" type="primary" @click="onSubmit">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {  Message } from "element-ui";
export default {
  name:'modal-input',
  props: ['value','id'],
  data() {
    return {
      dialogFormVisible: this.value,
      form: {
        organizationName: '',
        nikeName: '',
        userName: '',
        addList: [],
        valueData: '',
        address: '',
        projectList: [{addressList: []}], // 添加项目列表
        list: [], // 动态列表
        allList: []
      },
      
    }
  },
  created() {
    if (this.id) {
      this.getDetails();
    }
    this.getProjectList();
  },
  methods:{
    // 根据id获取详情
    async getDetails() {
      const api = this.$fetchApi.declareDetail.api;
      const data = await this.$fetchData.fetchPost('', api + this.id);
      if (data.code == '200') {
        this.form.organizationName = data.result.organizationName;
        this.form.nikeName = data.result.nikeName;
        this.form.userName = data.result.userName;
        data.result.itemResps.forEach((item, i) => {
          if (item.itemType != '3') {
            item.addressListType = [
              {
                address: '三亚',
                addressId: '00f35bd651f54756be04544ec189fda6'
              },
              {
                address: '陵水',
                addressId: '012d31bd0b094ec7ad550b7db2aacc06'
              },
              {
                address: '乐东',
                addressId: '012556c1c9f548f9a0461a43b46a6382'
              },
            ]
          } else {
            item.addressListType = [
              {
                address: '乐亚片区',
                addressId: '1'
              },
              {
                address: '陵水片区',
                addressId: '00f35bd651f54756be04544ec189fda6'
              },
            ]
          }
          item.addressList = item.addressList.map(item => item.address);
        })
        this.form.projectList = data.result.itemResps;
      }
    },
    // 项目列表
    async getProjectList() {
      const api = '/api/importantitem/backend/class/list';
      const data = await this.$fetchData.fetchPost('', api);
      if (data.code == '200') {
        this.form.list = data.result;
        this.form.allList = data.result;
      }
    },
    // 获取输入的手机号是否存在当前
    async userNameCheck() {
      const api = this.$fetchApi.isGetByPhone.api;
      const data = await this.$fetchData.fetchPost('', api + this.form.userName);
      if (data.result) {

      } else {
        this.form.userName = '';
        this.$message.error('该账号不存在，请重新填写');
      }
    },
    // 项目选择
    dateSelect(val, i) {
      this.form.projectList[i].addressListType = this.form.allList.filter(item => item.itemName == val)[0].addressResps;
      this.form.projectList[i].addressList = [];
      this.form.list = this.form.list.filter(item => item.itemName != val);
    },
    // 添加项目
    addProject() {
      this.form.projectList.push({addressList: []});
    },
    // 删除项目
    deleteBtn(i, val) {
      this.form.projectList.splice(i, 1);
      if (val.itemName) {
        const arr = this.form.allList.filter(item => item.itemName == val.itemName);
        this.form.list.push(arr[0])
      }
    },
    // 提交
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          console.log(this.form.projectList)
          this.form.addList = [];
          this.form.projectList.forEach((item , i) => {
            const arr = [];
            item.addressList.forEach(items => {
              return arr.push({
                address: items,
                addressId: items== '三亚' ? '00f35bd651f54756be04544ec189fda6' : 
                (items == '陵水' || items == '陵水片区' ? '012d31bd0b094ec7ad550b7db2aacc06' : (items == '乐东'
                ? '012556c1c9f548f9a0461a43b46a6382' : '1'))
              })
            })
            return this.form.addList.push({
              itemName: item.itemName,
              itemType: this.form.allList.filter(type => type.itemName == item.itemName)[0].itemType,
              addressList: arr
            })
          })
          const params = {
            id: this.id,
            itemReqs: this.form.addList,
            nikeName: this.form.nikeName, // 上报人
            organizationName: this.form.organizationName, // 项目负责单位
            userName: this.form.userName // 上报账号
          }
          const api = this.$fetchApi.addOrUpdate.api;
          const data = await this.$fetchData.fetchPost(params, api, "json");
          if (data.code == '200') {
            this.dialogFormVisible = false;
            this.$parent.getData();
          } else {
            this.$message(data.message);
          }
        } else {
          this.$message.error('请输入/选择必填项');
        }
      });
    },
  },
  watch:{
    value(newVal){
      this.dialogFormVisible = newVal;
    },
    dialogFormVisible(newVal){
      this.$emit('input', newVal);
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog__wrapper{
  display: grid;
  place-items: center;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
/deep/.el-dialog {
  width: 960px!important;
}
/deep/.el-radio{
  width: 80px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px!important;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-dialog--center .el-dialog__body{
  padding: 10px 20px;
}
.el-input{
  width: calc(100% - 100px);
  margin-right:10px
}
.font-bold{
  font-weight: bold;
}
.el-form-item{
  margin-bottom: 10px;
} 
.el-tag{
  margin: 4px 10px;
}
/deep/.el-dialog {
  width: 600px;
}
/deep/.el-dialog--center .el-dialog__body{
  padding-bottom: 0px;
}

.margin-left {
  margin-left: 10px;
}

.margin-top {
  margin-top: 25px;
}
/deep/ .el-input {
  width: 100%;
}

el-select /deep/ .select-popper {
  z-index: 88888 !important;
  top: auto !important;
  left: auto !important
}
</style>